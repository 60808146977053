import { createSlice } from "@reduxjs/toolkit";
import cleaningsService from "../services/cleanings.service";

const cleaningsSlice = createSlice({
  name: "cleanings",
  initialState: {
    entities: null,
    isLoading: true,
    error: null,
  },
  reducers: {
    cleaningsRequested: (state) => {
      state.isLoading = true;
    },
    cleaningsRecieved: (state, action) => {
      state.entities = action.payload;
      state.isLoading = false;
    },
    cleaningsRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

const { reducer: cleaningsReducer, actions } = cleaningsSlice;
const { cleaningsRequested, cleaningsRecieved, cleaningsRequestFailed } =
  actions;

export const loadCleaningsList = () => async (dispatch) => {
  dispatch(cleaningsRequested());
  try {
    const { content } = await cleaningsService.fetchAll();
    dispatch(cleaningsRecieved(content));
  } catch (error) {
    dispatch(cleaningsRequestFailed(error.message));
  }
};

export const getCleaningsLoadingStatus = () => (state) =>
  state.cleanings.isLoading;
export const getCleaningTypeByServiceType = (serviceType) => (state) => {
  if (state.cleanings.entities) {
    return state.cleanings.entities.find((c) => c.type === serviceType);
  }
};

export default cleaningsReducer;
