import httpService from "./http.service";
const cleaningsEndpoint = "cleanings";

const cleaningsService = {
  fetchAll: async () => {
    const { data } = await httpService.get(cleaningsEndpoint);
    return data;
  },
};

export default cleaningsService;
