import React from "react";
import { Link } from "react-router-dom";
import woman from "../../assets/woman.svg";
import man from "../../assets/man.svg";
import carpet from "../../assets/carpet.svg";
import sofa from "../../assets/sofa.svg";
import floorlamp from "../../assets/floorlamp.svg";
import window from "../../assets/window.svg";

const Banner2 = () => {
  return (
    <div className="bg-gradient-to-r from-[#34BDD3] to-[#34D399] h-[550px] relative">
      <div className="max-w-auto sm:px-4 md:px-6 lg:px-8 max-[640px]:px-1 absolute uppercase font-['Avenir'] font-bold lg:text-7xl/[90px] max-[640px]:text-4xl min-[774px]:text-6xl/[70px] text-4xl top-[30px]">
        <div className="lg:max-w-[405px] md:max-w-[350px] justify-center items-center break-words">
          <h1 className="uppercase text-white">
            With love to you and your home
          </h1>
        </div>
        <div className="min-[280px]:mt-[270px] min-[372px]:mt-[280px]  min-[385px]:mt-[320px] min-[750px]:mt-[280px] min-[773px]:mt-[310px] min-[774px]:mt-[20px]">
          <div className="md:max-w-[340px] lg:max-w-[400px] mt-[20px] text-center text-2xl font-bold bg-[#FFF323] rounded-full py-3 transition duration-300 ease-in-out hover:text-white hover:bg-[#c9c01c] ">
            <Link
              to="/book"
              className="min-[350px]:px-[100px] min-[350px]:py-[12px] min-[470px]:px-[160px] font-['Gabarito'] min-[570px]:px-[180px] min-[768px]:px-[90px] lg:px-[132px] lg:py-[17px]"
            >
              Book Now
            </Link>
          </div>
          <div className="md:max-w-[360px] lg:max-w-[400px] text-center font-['Avenir'] text-base lg:text-lg px-4 min-[280px]:mt-[8px] min-[350px]:mt-[12px] min-[540px]:mt-[20px] min-[768px]:mt-[10px] lg:mt-[25px]">
            <p className="">
              *Get <span className="text-[#ff0000]">20% Off</span> on your first
              cleaning{" "}
            </p>
          </div>
        </div>
      </div>

      <img
        className="absolute min-[390px]:h-[100px] min-[390px]:left-[150px] min-[390px]:top-[155px] min-[320px]:h-[100px] min-[320px]:left-[120px] min-[320px]:top-[160px] min-[555px]:h-[150px] min-[555px]:left-[330px] min-[555px]:top-[70px] min-[774px]:h-[210px] min-[774px]:left-[530px] min-[774px]:top-[50px] min-[774px]:left-[650px] min-[1024px]:h-[210px] min-[1024px]:left-[700px] min-[1024px]:top-[50px] min-[1024px]:left-[700px] min-[1285px]:top-[3px] min-[1285px]:left-[940px] min-[1285px]:h-[264px]"
        src={window}
        alt="Window"
      />
      <img
        className="absolute min-[390px]:top-[280px] min-[390px]:h-[130px] min-[390px]:left-[50px]          min-[320px]:top-[280px] min-[320px]:h-[130px] min-[320px]:left-[10px] min-[555px]:top-[250px] min-[555px]:h-[160px] min-[555px]:left-[90px] min-[774px]:top-[290px] min-[774px]:h-[180px] min-[774px]:left-[370px] min-[1024px]:top-[290px] min-[1024px]:h-[215px] min-[1024px]:left-[510px] min-[1285px]:top-[220px] min-[1285px]:left-[700px] min-[1285px]:h-[275px]"
        src={carpet}
        alt="Carpet"
      />
      <img
        className="absolute min-[390px]:h-[130px] min-[390px]:left-[140px] min-[390px]:top-[235px]   min-[320px]:h-[130px] min-[320px]:left-[100px] min-[320px]:top-[235px] min-[555px]:h-[155px] min-[555px]:left-[220px] min-[555px]:top-[200px] min-[774px]:h-[185px] min-[774px]:left-[500px] min-[774px]:top-[225px] min-[1024px]:h-[200px] min-[1024px]:left-[690px] min-[1024px]:top-[230px] min-[1285px]:top-[158px] min-[1285px]:left-[940px] min-[1285px]:h-[241px]"
        src={sofa}
        alt="Sofa"
      />
      <img
        className="absolute min-[390px]:h-[90px] min-[390px]:left-[290px] min-[390px]:top-[315px]     min-[320px]:h-[90px] min-[320px]:left-[250px] min-[320px]:top-[315px] min-[555px]:h-[140px] min-[555px]:left-[400px] min-[555px]:top-[260px] min-[774px]:h-[170px] min-[774px]:left-[680px] min-[774px]:top-[300px] min-[1024px]:h-[200px] min-[1024px]:left-[900px] min-[1024px]:top-[300px] min-[1285px]:top-[240px] min-[1285px]:left-[1190px] min-[1285px]:h-[273px]"
        src="https://firebasestorage.googleapis.com/v0/b/mint-cleaning-9b9dc.appspot.com/o/plant.svg?alt=media&token=699d05df-4652-4e19-9e23-317de74045a2"
        alt="Plant"
      />
      <img
        className="absolute min-[390px]:h-[120px] min-[390px]:left-[90px] min-[390px]:top-[210px]     min-[320px]:h-[120px] min-[320px]:left-[47px] min-[320px]:top-[210px] min-[555px]:h-[150px] min-[555px]:left-[150px] min-[555px]:top-[160px] min-[774px]:h-[150px] min-[774px]:left-[430px] min-[774px]:top-[200px] min-[1024px]:h-[210px] min-[1024px]:left-[600px] min-[1024px]:top-[150px] min-[1285px]:top-[50px] min-[1285px]:left-[785px] min-[1285px]:h-[294px]"
        src={floorlamp}
        alt="Floor Lamp"
      />
      <img
        className="absolute min-[390px]:h-[140px] min-[390px]:left-[32px] min-[390px]:top-[210px]   min-[320px]:h-[140px] min-[320px]:left-[0px] min-[320px]:top-[210px] min-[555px]:h-[180px] min-[555px]:left-[70px] min-[555px]:top-[170px] min-[774px]:h-[250px] min-[774px]:left-[330px] min-[774px]:top-[140px] min-[1024px]:h-[280px] min-[1024px]:left-[460px] min-[1024px]:top-[120px] min-[1285px]:top-[25px] min-[1285px]:left-[640px] min-[1285px]:h-[342px]"
        src={man}
        alt="Man"
      />
      <img
        className="absolute  min-[390px]:h-[170px] min-[390px]:left-[110px] min-[390px]:top-[250px]     min-[320px]:h-[170px] min-[320px]:left-[70px] min-[320px]:top-[250px] min-[555px]:h-[230px] min-[555px]:left-[180px] min-[555px]:top-[190px] min-[774px]:h-[330px] min-[774px]:left-[400px] min-[774px]:top-[190px] min-[1024px]:h-[340px] min-[1024px]:left-[570px] min-[1024px]:top-[190px] min-[1285px]:top-[130px] min-[1285px]:left-[800px] min-[1285px]:h-[394px]"
        src={woman}
        alt="Woman"
      />
    </div>
  );
};

export default Banner2;
