import httpService from "./http.service";
const bookNowEndpoint = "book/booknow";
const getQuoteEndpoint = "book/getquote";

const bookingService = {
  bookNow: async (payload) => {
    const { data } = await httpService.put(bookNowEndpoint, payload);
    return data;
  },
  getQuote: async (payload) => {
    const { data } = await httpService.put(getQuoteEndpoint, payload);
    return data;
  },
};

export default bookingService;
