import React, { useCallback, useState, useEffect } from "react";
import ContactUs from "../../../../assets/ContactUs.png";
import { validator } from "../../../utils/validator";
import TextField from "../../textField";
import { contact } from "../../../store/contact";
import contactUsSm from "../../../../assets/contactUs.svg";
import { Link, useNavigate } from "react-router-dom";

const ContactPage = () => {
  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [buttonClicked, setButtonClicked] = useState(false);

  const validate = useCallback((data) => {
    const errors = validator(data, validatorConfig);
    setErrors(errors);
    return Object.keys(errors).length === 0;
  }, []);

  const validatorConfig = {
    firstname: { isRequired: { message: "First Name is required" } },
    lastname: { isRequired: { message: "Last Name is required" } },
    email: {
      isRequired: { message: "Email is required" },
      isEmail: { message: "Email entered incorrectly" },
    },
    message: { isRequired: { message: "Message is required" } },
  };

  const handleSubmit = async (e) => {
    setButtonClicked(true);
    e.preventDefault();
    const isValid = validate(data);
    if (!isValid) return;
    contact(data);
    navigate("/requestsubmitted");
  };

  const handleChange = ({ target }) => {
    setData((prevState) => {
      return { ...prevState, [target.name]: target.value };
    });
  };

  useEffect(() => {
    if (buttonClicked) {
      validate(data);
    }
  }, [data, buttonClicked]);

  return (
    <div className="min-h-screen flex flex-col justify-start">
      <div className="text-center relative max-md:mt-4 max-md:px-4">
        <img
          src={ContactUs}
          width={1920}
          height={400}
          className="md:w-full max-[640px]:h-[140px] max-md:hidden"
          alt="Image"
        />
        <img
          src={contactUsSm}
          width={430}
          height={245}
          className="rounded-[20px] mx-auto md:hidden"
          alt="Small Image"
        />
      </div>
      <div className="flex flex-col lg:flex-row lg:justify-between px-[20px] lg:px-[50px] mt-[30px] mb-[30px] md:mb-[60px]">
        <div className="md:w-[600px] md:mt-[30px] font-[Avenir]">
          <h1 className="mb-[40px] text-4xl font-bold max-md:mb max-md:text-center normal-case tracking-normal">
            What assistance can we provide?
          </h1>
          <p className="text-2xl font-semibold max-md:text-center">
            You can find all prices on our booking page. Simply click{" "}
            <Link
              to="/book"
              className="text-emerald-400 hover:text-emerald-500 transition ease-in-out duration-300"
            >
              here
            </Link>{" "}
            for easy access.
          </p>
        </div>
        <form onSubmit={handleSubmit} className="w-full max-w-[550px]">
          <div className="max-md:grid max-md:grid-cols-1">
            <div className=" gap-x-6 gap-y-8">
              <div className="md:flex md:justify-between">
                <TextField
                  type="text"
                  name="firstname"
                  placeholder="First Name*"
                  value={data.firstname}
                  onChange={handleChange}
                  error={errors.firstname}
                  className1="w-full md:max-w-[250px] lg:max-w-[270px]"
                  className2="font-[Avenir] block text-sm font-medium leading-6 text-gray-900"
                  className3="mt-8"
                  className4="font-[Avenir] block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-[#71d1a7] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#71d1a7] sm:text-md sm:leading-6"
                />
                <TextField
                  type="text"
                  name="lastname"
                  placeholder="Last Name*"
                  value={data.lastname}
                  onChange={handleChange}
                  error={errors.lastname}
                  className1="w-full md:max-w-[250px] lg:max-w-[270px]"
                  className2="font-[Avenir] block text-sm font-medium leading-6 text-gray-900"
                  className3="mt-5 md:mt-8"
                  className4="font-[Avenir] block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-[#71d1a7] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#71d1a7] sm:text-md sm:leading-6"
                />
              </div>

              <TextField
                type="text"
                name="email"
                placeholder="Email*"
                value={data.email}
                onChange={handleChange}
                error={errors.email}
                className1="mt-5"
                className2="block text-sm font-medium leading-6 text-gray-900 font-[Avenir]"
                className3="mt-2"
                className4="font-[Avenir] block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-[#71d1a7] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#71d1a7] sm:text-md sm:leading-6"
              />

              <div className="mt-5">
                <textarea
                  id="message"
                  placeholder="Message*"
                  name="message"
                  rows={3}
                  onChange={handleChange}
                  value={data.message}
                  className="placeholder:text-gray-400 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#71d1a7] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#71d1a7] sm:text-md sm:leading-6"
                />
                {errors.message && (
                  <p className="mt-2 ml-1 text-sm text-red-600">
                    {errors.message}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="mt-7 md:mt-7 flex items-center justify-start flex items-center justify-center">
            <button
              type="submit"
              className="uppercase tracking-wider font-[Avenir] font-semibold rounded-md bg-[#34BDD3] max-md:px-[130px] md:px-[190px] py-3 text-md font-medium text-white shadow-sm hover:bg-[#239cb0] transition duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#34BDD3]"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactPage;
