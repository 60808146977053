import React from "react";
import { Link } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { BsFacebook } from "react-icons/bs";
import footer from "../../../assets/footer.png";

const Footer = () => {
  return (
    <footer className="bg-gradient-to-r from-[#34BDD3] to-[#34D399] rounded-t-md mt-auto">
      <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
          <div>
            <div className="max-w-[250px]">
              <Link to="/" className="shadow-2xl">
                <img
                  src={footer}
                  width={209}
                  height={90}
                  className="h-20 sm:h-20"
                  alt="Mint Cleaning Footer Logo"
                />
              </Link>
            </div>
            <div className="max-w-xs mt-4 text-base text-gray-600">
              <div className="mb-2">
                <a
                  href="tel:9024414854"
                  className="font-medium hover:opacity-50 text-white transition ease-in-out duration-200"
                >
                  (902) 441-4854
                </a>
              </div>
              <div className="font-medium hover:opacity-50 text-white transition ease-in-out duration-200 max-w-[250px]">
                <a
                  href="mailto:contact@mint-cleaning.ca"
                  className="font-medium"
                >
                  contact@mint-cleaning.ca
                </a>
              </div>
            </div>
            <div className="flex mt-8 space-x-6 text-gray-600">
              <a
                className="hover:opacity-50 text-[#0866FF] transition ease-in-out duration-300 cursor-pointer"
                target="_blank"
                href="https://www.facebook.com/profile.php?id=61553858343112&mibextid=hrBMPu"
              >
                <span className="sr-only"> Facebook </span>
                <BsFacebook className="w-8 h-8" />
              </a>

              {/* <a
                className="hover:opacity-50 text-[#0866FF] transition ease-in-out duration-300 cursor-pointer"
                target="_blank"
                href="https://www.google.com/"
              >
                <span className="sr-only"> Google </span>
                <FcGoogle className="w-[34px] h-[34px]" />
              </a> */}
            </div>
          </div>
          <div className="grid grid-cols-1 gap-8 lg:col-span-2 sm:grid-cols-2 lg:grid-cols-4">
            {/* <div>
              <p className="font-bold font-['Open_Sans']">Company</p>
              <nav className="flex flex-col mt-4 space-y-2 text-sm text-gray-500">
                <Link className="hover:opacity-75 font-medium">About</Link>
                <Link className="hover:opacity-75 font-medium">Careers</Link>
              </nav>
            </div> */}
            <div className="font-[Avenir]">
              <p className="font-bold text-lg text-white">Services</p>
              <nav className="flex flex-col mt-4 text-white space-y-2 text-base text-gray-500">
                <Link
                  to="/book"
                  className="hover:opacity-50 transition ease-in-out duration-200 font-medium max-w-[38px]"
                >
                  Book
                </Link>
                <Link
                  to="/services/apartment-and-condo-cleaning"
                  className="hover:opacity-50 font-medium transition ease-in-out duration-200 max-w-[150px]"
                >
                  Apartment & Condo
                </Link>
                <Link
                  to="/services/on-going-cleaning"
                  className="hover:opacity-50 font-medium transition ease-in-out duration-200 max-w-[143px]"
                >
                  On-Going Cleaning
                </Link>
                <Link
                  to="/services/one-time-cleaning"
                  className="hover:opacity-50 font-medium transition ease-in-out duration-200 max-w-[143px]"
                >
                  One-Time Cleaning
                </Link>
                <Link
                  to="/services/move-in-move-out-cleaning"
                  className="hover:opacity-50 font-medium transition ease-in-out duration-200 max-w-[155px]"
                >
                  Move In & Move Out
                </Link>
                <Link
                  to="/services/deep-cleaning"
                  className="hover:opacity-50 font-medium transition ease-in-out duration-200 max-w-[113px]"
                >
                  Deep Cleaning
                </Link>
              </nav>
            </div>
            <div className="font-[Avenir] text-white">
              <p className="font-bold text-lg">Helpful Links</p>
              <nav className="flex flex-col mt-4 text-white space-y-2 text-base text-gray-500">
                <Link
                  to="/contact"
                  className="hover:opacity-50 font-medium transition ease-in-out duration-200 max-w-[51px]"
                >
                  Contact
                </Link>
                <Link
                  to="/faqs"
                  className="hover:opacity-50 font-medium transition ease-in-out duration-200 max-w-[33px]"
                >
                  FAQ
                </Link>
              </nav>
            </div>
            <div className="font-[Avenir] text-white">
              <p className="font-bold text-lg">Legal</p>
              <nav className="flex flex-col mt-4 text-white space-y-2 text-base text-gray-500">
                <Link
                  to="/privacy-policy"
                  className="hover:opacity-50 font-medium transition ease-in-out duration-200 max-w-[102px]"
                >
                  Privacy Policy
                </Link>
                <Link
                  to="/terms-and-conditions"
                  className="hover:opacity-50 font-medium transition ease-in-out duration-200 max-w-[144px]"
                >
                  {" "}
                  Terms &amp; Conditions{" "}
                </Link>
              </nav>
            </div>
          </div>
        </div>
        <p className="mt-8 text-xs text-white font-[Avenir]">
          © 2024 Mint Cleaning. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
