import { Navigate } from "react-router-dom";
import MainPage from "./app/components/page/mainPage/mainPage";
import ContactPage from "./app/components/page/contactPage/contactPage";
import FAQsPage from "./app/components/page/FAQsPage/faqspage";
import CleaningPage from "./app/components/page/cleaningPage/cleaningPage";
import BookingPage from "./app/components/page/bookingPage/bookingPage";
import SuccessfulOperationPage from "./app/components/page/successfulOperationPage/successfulOperationPage";
import GetQuotePage from "./app/components/page/getQuotePage/getQuotePage";
import SuccessfulQuotePage from "./app/components/page/successfulQuotePage/successfulQuotePage";
import PrivacyPolicyPage from "./app/components/page/privacyPolicyPage/privacyPolicyPage";
import TermsConditionsPage from "./app/components/page/TermsConditionsPage/termsConditionsPage";

const routes = () => [
  {
    path: "/",
    element: <MainPage />,
  },
  {
    path: "/contact",
    element: <ContactPage />,
  },
  {
    path: "/faqs",
    element: <FAQsPage />,
  },
  {
    path: "/book",
    element: <BookingPage />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicyPage />,
  },
  {
    path: "/terms-and-conditions",
    element: <TermsConditionsPage />,
  },
  {
    path: "/getquote",
    element: <GetQuotePage />,
  },
  {
    path: "/booked",
    element: <SuccessfulOperationPage />,
  },
  {
    path: "/quotesubmitted",
    element: <SuccessfulQuotePage />,
  },
  {
    path: "/requestsubmitted",
    element: <SuccessfulQuotePage />,
  },
  {
    path: "services",
    children: [
      {
        path: ":serviceType",
        element: <CleaningPage />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },
];

export default routes;
