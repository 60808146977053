export const bedroomPrices = {
  "One Bedroom": 135,
  "Two Bedroom": 150,
  "Three Bedroom": 165,
  "Four Bedroom": 175,
  "Five Bedroom": 185,
  "Six Bedroom": 225,
};

export const bathroomPrices = {
  "0 Full Bathroom": 0,
  "1 Full Bathroom": 30,
  "2 Full Bathrooms": 60,
  "3 Full Bathrooms": 90,
  "4 Full Bathrooms": 120,
  "5 Full Bathrooms": 150,
  "6 Full Bathrooms": 180,
};

export const halfbathroomPrices = {
  "0 Half Bathroom": 0,
  "1 Half Bathroom": 15,
  "2 Half Bathrooms": 30,
  "3 Half Bathrooms": 55,
  "4 Half Bathrooms": 60,
  "5 Half Bathrooms": 75,
  "6 Half Bathrooms": 90,
};

export const hourlyPrices = {
  "1 Hour": 80,
  "1.5 Hours": 120,
  "2 Hours": 160,
  "2.5 Hours": 200,
  "3 Hours": 240,
  "3.5 Hours": 280,
  "4 Hours": 320,
  "4.5 Hours": 360,
  "5 Hours": 400,
  "5.5 Hours": 440,
  "6 Hours": 480,
  "6.5 Hours": 520,
  "7 Hours": 560,
  "7.5 Hours": 600,
  "8 Hours": 640,
};

export const deepCleaningPrices = {
  "One Bedroom": 175,
  "Two Bedroom": 175,
  "Three Bedroom": 205,
  "Four Bedroom": 205,
  "Five Bedroom": 270,
  "Six Bedroom": 270,
};

export const windowsPrices = {
  "One Bedroom": 20,
  "Two Bedroom": 20,
  "Three Bedroom": 35,
  "Four Bedroom": 35,
  "Five Bedroom": 50,
  "Six Bedroom": 50,
};
