import React from "react";
import Banner from "../../banner";
import FeatureSection from "../../featureSection";
import Stats from "../../stats";
import Slider from "../../slider/Slider";

const MainPage = () => {
  return (
    <div className="min-h-[1800px]">
      <Banner />
      <FeatureSection />
      <Stats />
      <Slider />
    </div>
  );
};

export default MainPage;
