import React from "react";
import { CalendarDaysIcon } from "@heroicons/react/20/solid";
import { GiVacuumCleaner } from "react-icons/gi";
import { BsFillHouseCheckFill } from "react-icons/bs";
import family from "../../assets/family.png";

const features = [
  {
    name: "ONLINE BOOKING",
    description:
      "Easily schedule your cleaning for the day and time that suits you best with our online booking.",
    icon: CalendarDaysIcon,
  },
  {
    name: "THEY CLEAN",
    description:
      "Watch as our cleaning experts transform your space into a sparkling haven.",
    icon: GiVacuumCleaner,
  },
  {
    name: "YOU RELAX",
    description:
      "Enjoy your new clean home and all the extra time you now have.",
    icon: BsFillHouseCheckFill,
  },
];

const FeatureSection = () => {
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-9">
            <div className="lg:max-w-lg">
              <h2 className="text-lg font-semibold leading-7 text-[#34D399] font-['Avenir']">
                …easy as 1-2-3
              </h2>
              <p className="mt-2 text-3xl font-['Avenir'] font-bold tracking-tight text-gray-900 sm:text-4xl">
                How We Work:
              </p>

              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div
                    key={feature.name}
                    className="relative pl-[50px] md:pl-9 lg:pl-[70px]"
                  >
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon
                        className="absolute min-[350px]:left-[0px] md:left-[-5px] top-1 h-9 w-9 md:h-14 md:w-14 text-[#34D399]"
                        aria-hidden="true"
                      />

                      <p className="text-xl md:text-2xl font-['Avenir']">
                        {feature.name}
                      </p>
                    </dt>{" "}
                    <dd className="inline text-xl md:text-2xl font-['Avenir']">
                      {feature.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            src={family}
            alt="Image"
            className="opacity-80  w-[48rem] min-[350px]:w-[25rem] sm:w-[30rem] md:w-[40rem] lg:w-[57rem] md:max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10  md:-ml-4 lg:-ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>
  );
};

export default FeatureSection;
