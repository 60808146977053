import { createSlice } from "@reduxjs/toolkit";
import extrasService from "../services/extras.service";

const extrasSlice = createSlice({
  name: "extras",
  initialState: {
    entities: null,
    isLoading: true,
    error: null,
  },
  reducers: {
    extrasRequested: (state) => {
      state.isLoading = true;
    },
    extrasRecieved: (state, action) => {
      state.entities = action.payload;
      state.isLoading = false;
    },
    extrasRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

const { reducer: extrasReducer, actions } = extrasSlice;
const { extrasRequested, extrasRecieved, extrasRequestFailed } = actions;

export const loadExtrasList = () => async (dispatch) => {
  dispatch(extrasRequested());
  try {
    const { content } = await extrasService.fetchAll();
    dispatch(extrasRecieved(content));
  } catch (error) {
    dispatch(extrasRequestFailed(error.message));
  }
};

export const getExtras = () => (state) => state.extras.entities;

export const getDeepCleaningPrice = (extra) => (state) => {
  if (state.extras.entities) {
    return state.extras.entities.find((e) => e === extra);
  }
};
export const getExtrasLoadingStatus = () => (state) => state.extras.isLoading;

export default extrasReducer;
