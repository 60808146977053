import { combineReducers, configureStore } from "@reduxjs/toolkit";
import extrasReducer from "./extras";
import cleaningsReducer from "./cleanings";
import frequenciesReducer from "./frequency";

const rootReducer = combineReducers({
  extras: extrasReducer,
  cleanings: cleaningsReducer,
  frequencies: frequenciesReducer,
});

export function createStore() {
  return configureStore({
    reducer: rootReducer,
  });
}
