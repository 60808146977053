import React from "react";
import lamp from "../../assets/lamp2.svg";
import pets from "../../assets/pets.svg";
import securePayment from "../../assets/securePayment.svg";
import customerService from "../../assets/customerService.svg";
import calendar from "../../assets/calendar.svg";
import trustUs from "../../assets/trustUs.svg";

const Stats = () => {
  return (
    <div className="bg-[#F6F4F6] h-full">
      <div className="py-14 px-[30px]">
        <p className="text-center text-[#565F73] text-4xl md:text-5xl mb-10 font-['Avenir'] font-bold">
          Your space is safe with us
        </p>
        <div className="px-2 md:px-4 grid grid-cols-1 sm:grid-cols-3 gap-5 gap-y-10 md:gap-y-5 items-start">
          <div className="min-h-[100px] py-3">
            <div className="mb-2 text-2xl font-['Avenir'] tracking-wide font-semibold flex flex-col justify-center items-center uppercase">
              <img src={pets} className="h-[100px] w-[100px] mb-2" />
              <p className="text-[#565F73]">Pet care</p>
            </div>
            <p className="md:px-2 font-['Avenir'] text-center text-lg font-light">
              Your pet's dishes are washed and filled with fresh water and food.
            </p>
          </div>
          <div className="min-h-[100px] py-3 ">
            <div className="text-2xl mb-2 font-['Avenir'] font-semibold flex flex-col justify-center items-center uppercase">
              <img src={securePayment} className="h-[100px] w-[100px] mb-2" />
              <p className="text-[#565F73] text-center">
                Secure Online Payment
              </p>
            </div>
            <p className="md:px-2 font-['Avenir'] text-center text-lg font-light">
              Once the cleaning is complete, your payment is made securely
              online.
            </p>
          </div>

          <div className="min-h-[100px] py-3">
            <div className="mb-2 text-2xl font-['Avenir'] font-semibold flex flex-col justify-center items-center uppercase">
              <img src={customerService} className="h-[100px] w-[100px] mb-2" />
              <p className="text-[#565F73]">Trusted Team</p>
            </div>
            <p className="md:px-2 font-['Avenir'] text-center text-lg">
              Our customer service is always ready to help.
            </p>
          </div>

          <div className="min-h-[100px] py-3 md:mt-8">
            <div className="mb-2 text-2xl font-['Avenir'] font-semibold flex flex-col justify-center items-center uppercase">
              <img src={calendar} className="h-[100px] w-[100px] mb-2" />
              <p className="text-[#565F73]">It's That Easy</p>
            </div>
            <p className="md:px-2 font-['Avenir'] text-center text-lg">
              Select the number of bedrooms, pick your date & time, and our
              cleaning services team will be there for you!
            </p>
          </div>

          <div className="min-h-[100px] py-3 md:mt-8">
            <div className="mb-2 text-2xl font-['Avenir'] font-semibold flex flex-col justify-center items-center uppercase">
              <img src={trustUs} className="h-[100px] w-[100px] mb-2" />
              <p className="text-[#565F73]">Outstanding work</p>
            </div>
            <p className="md:px-2 font-['Avenir'] text-center text-lg">
              Our dedicated team consistently provides exceptional service,
              ensuring quality work is completed every time, all employees
              undergo strict background checks and are thoroughly screened.
            </p>
          </div>

          <div className="min-h-[100px] py-3 md:mt-8">
            <div className="mb-2 text-2xl font-['Avenir'] font-semibold flex flex-col justify-center items-center uppercase">
              <img src={lamp} className="h-[100px] w-[100px] mb-2" />
              <p className="text-[#565F73] text-center">
                Particular attention to detail
              </p>
            </div>
            <p className="md:px-2 font-['Avenir'] text-center text-lg">
              We are attentive to details and little things, we do it quickly
              and carefully.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
