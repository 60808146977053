import React from "react";
import { BsCheck2Circle, BsHouse } from "react-icons/bs";
import { Link } from "react-router-dom";

const SuccessfulQuotePage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-[500px]">
      <div>
        <BsCheck2Circle className="w-[120px] h-[120px] text-emerald-500" />
      </div>
      <div className="text-2xl mt-[15px] font-semibold max-lg:max-w-[380px] text-center mx-auto">
        Thank you for contacting us, we will get back to you as soon as
        possible.
      </div>
      <Link
        to="/"
        className="flex items-center hover:shadow-md shadow-sm border px-10 py-2 mt-[40px] border-gray-700 border-2 rounded-md hover:border-gray-400 transition duration-300 ease-in-out"
      >
        <BsHouse className="w-5 h-5 mr-3" /> Go Home
      </Link>
    </div>
  );
};

export default SuccessfulQuotePage;
