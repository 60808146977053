import { React, Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

const solutions = [
  {
    name: "Apartment & Condo",
    to: "/services/apartment-and-condo-cleaning",
  },
  {
    name: "On-Going Cleaning",
    to: "/services/on-going-cleaning",
  },
  {
    name: "One-Time Cleaning",
    to: "/services/one-time-cleaning",
  },
  {
    name: "Move In & Move Out",
    to: "/services/move-in-move-out-cleaning",
  },
  {
    name: "Deep Cleaning",
    to: "/services/deep-cleaning",
  },
];

const ServicesDropdown = () => {
  return (
    <Popover className="relative font-[Avenir]">
      {({ open, close }) => (
        <>
          <Popover.Button className="inline-flex items-center outline-none max-[1066px]:max-w-[350px] max-[1066px]:w-full">
            <span className="outline-none mr-1">SERVICES</span>
            <div
              className={`icon-transition ${
                open ? "transform rotate-180" : ""
              }`}
            >
              <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
            </div>
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-300"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-300"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
              <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {solutions.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex gap-x-2 rounded-lg p-2 hover:bg-emerald-400 font-['Avenir'] uppercase duration-[130ms] ease-in-out hover:text-white transition"
                    >
                      <div className="mt-1 flex h-1 w-1 flex-none items-start rounded-lg"></div>
                      <div>
                        <Link
                          to={item.to}
                          className="font-semibold text-gray-900"
                          onClick={() => close()}
                        >
                          {item.name}
                          <span className="absolute inset-0" />
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default ServicesDropdown;
