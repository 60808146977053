import React, { useState, useEffect } from "react";
import "./Slider.css";
import data from "./data";
import { BsChevronDoubleRight, BsChevronDoubleLeft } from "react-icons/bs";

const Slider = () => {
  const [people] = useState(data);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const lastIndex = people.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, people]);

  useEffect(() => {
    let slider = setInterval(() => {
      setIndex(index + 1);
    }, 10000);
    return () => {
      clearInterval(slider);
    };
  }, [index]);

  return (
    <section className="section">
      <div className="title">
        <h2>What Clients Say:</h2>
      </div>
      <div className="section-center">
        {people.map((item, indexPeople) => {
          const { id, image, name, quote } = item;
          let position = "nextSlide";
          if (indexPeople === index) {
            position = "activeSlide";
          }
          if (
            indexPeople === index - 1 ||
            (index === 0 && indexPeople === people.length - 1)
          ) {
            position = "lastSlide";
          }
          return (
            <article className={position} key={id}>
              <div className="flex items-center justify-center">
                <img
                  src={image}
                  alt={name}
                  width={1500}
                  height={890}
                  className="person-img"
                />
              </div>
              <h3>{name}</h3>
              <p className="text break-normal">{quote}</p>
            </article>
          );
        })}
        <button
          className="prev hover:bg-emerald-500"
          onClick={() => setIndex(index - 1)}
        >
          <BsChevronDoubleLeft className="fas fa-arrow-left" />
        </button>
        <button
          className="next hover:bg-emerald-500"
          onClick={() => setIndex(index + 1)}
        >
          <BsChevronDoubleRight className="fas fa-arrow-right" />
        </button>
      </div>
    </section>
  );
};

export default Slider;
