import React, { useCallback, useState, useEffect } from "react";
import TextField from "../../textField";
import { validator } from "../../../utils/validator";
import { Link, useNavigate } from "react-router-dom";
import { getQuote } from "../../../store/booking";

const GetQuotePage = () => {
  const [buttonClicked, setButtonClicked] = useState(false);
  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = ({ target }) => {
    setData((prevState) => {
      return { ...prevState, [target.name]: target.value };
    });
  };

  const validatorConfig = {
    firstname: { isRequired: { message: "First Name is required" } },
    lastname: { isRequired: { message: "Last Name is required" } },
    email: {
      isRequired: { message: "Email is required" },
      isEmail: { message: "Email entered incorrectly" },
    },
    phone: { isRequired: { message: "Phone is required" } },
  };

  const validate = useCallback((data) => {
    const errors = validator(data, validatorConfig);
    setErrors(errors);
    return Object.keys(errors).length === 0;
  }, []);

  const handleSubmit = (e) => {
    setButtonClicked(true);
    e.preventDefault();
    const isValid = validate(data);
    if (!isValid) return;
    getQuote(data);
    navigate("/quotesubmitted");
  };

  useEffect(() => {
    if (buttonClicked) {
      validate(data);
    }
  }, [data, buttonClicked]);

  return (
    <div className="bg-[#F6F4F6] min-h-screen flex flex-col justify-start">
      <div className="">
        <div className="text-center pt-[50px] ">
          <p className="text-5xl font-semibold font-[Avenir]">Get a Quote!</p>
          <div className="mt-10">
            {" "}
            <p className="text-lg max-w-[305px]  md:max-w-[950px] mx-auto font-[Avenir]">
              At Mint Cleaning, customer service is our main priority. Our
              friendly staff are waiting to serve you.
            </p>
            <p className="text-lg max-w-[320px] md:max-w-[997px] mx-auto font-[Avenir]">
              Feel free to visit our{" "}
              <Link
                to="/book"
                className="text-emerald-400 font-semibold font-[Avenir] hover:text-emerald-500 transition ease-in-out duration-200"
              >
                Book Now page
              </Link>{" "}
              for an instant quote, give us a call at{" "}
              <span className="text-emerald-400 font-semibold font-[Avenir]">
                <a
                  href="tel:9024414854"
                  className="font-semibold font-[Avenir] hover:text-emerald-500 transition ease-in-out duration-200"
                >
                  (902) 441-4854
                </a>
              </span>{" "}
              or submit a service inquiry below.
            </p>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="space-y-8">
          <div className="mt-[30px] mx-[20px]">
            <div className="mx-auto max-w-[800px]">
              <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
                <TextField
                  type="text"
                  name="firstname"
                  placeholder="First Name*"
                  value={data.firstname}
                  onChange={handleChange}
                  error={errors.firstname}
                  className1="sm:col-span-3 font-[Avenir]"
                  className2="block text-sm font-medium leading-6 text-gray-900 font-[Avenir]"
                  className3="mt-2 font-[Avenir]"
                  className4="font-[Avenir] block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-[#71d1a7] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#71d1a7] text-sm md:text-base lg:text-base sm:leading-6"
                />
                <TextField
                  type="text"
                  name="lastname"
                  placeholder="Last Name*"
                  value={data.lastname}
                  onChange={handleChange}
                  error={errors.lastname}
                  className1="sm:col-span-3 font-[Avenir]"
                  className2="block text-sm font-medium leading-6 text-gray-900 font-[Avenir]"
                  className3="mt-2 font-[Avenir]"
                  className4="font-[Avenir] block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-[#71d1a7] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#71d1a7] text-sm md:text-base lg:text-base sm:leading-6"
                />
                <TextField
                  type="text"
                  name="email"
                  placeholder="Email*"
                  value={data.email}
                  onChange={handleChange}
                  error={errors.email}
                  className1="font-[Avenir] sm:col-span-3"
                  className2="font-[Avenir] block text-sm font-medium leading-6 text-gray-900"
                  className3="font-[Avenir] mt-2"
                  className4="font-[Avenir] block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-[#71d1a7] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#71d1a7] text-sm md:text-base lg:text-base sm:leading-6"
                />
                <TextField
                  type="text"
                  name="phone"
                  placeholder="Phone*"
                  value={data.phone}
                  onChange={handleChange}
                  error={errors.phone}
                  className1="font-[Avenir] sm:col-span-3"
                  className2="font-[Avenir] block text-sm font-medium leading-6 text-gray-900"
                  className3="font-[Avenir] mt-2"
                  className4="font-[Avenir] block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-[#71d1a7] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#71d1a7] text-sm md:text-base lg:text-base sm:leading-6"
                />
              </div>
            </div>
          </div>
          <div className="mt-[30px] mx-[20px]">
            <div className="mx-auto max-w-[800px] pb-7">
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="col-span-full">
                  <div>
                    <textarea
                      id="message"
                      name="message"
                      rows={3}
                      placeholder="Message"
                      value={data.message}
                      onChange={handleChange}
                      className="font-[Avenir] block w-full rounded-md border-0 max-md:py-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#71d1a7] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#71d1a7] text-sm md:text-base lg:text-base sm:leading-6"
                    />
                  </div>
                  {/* <p className="text-[13px] mt-3 ml-1">* Required Field</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col max-w-[800px] mx-auto rounded-md">
          <div className="uppercase md:mt-3 gap-y-2 flex flex-col text-xl px-7 mb-4">
            <button
              type="submit"
              className="font-[Avenir] mb-1 uppercase tracking-wider rounded-md bg-[#34BDD3] py-3 text-sm font-semibold text-white shadow-sm hover:bg-[#239cb0] transition duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#34BDD3]"
            >
              Send
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default GetQuotePage;
