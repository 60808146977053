import { createSlice } from "@reduxjs/toolkit";
import frequenciesService from "../services/frequencies.service";

const frequenciesSlice = createSlice({
  name: "frequencies",
  initialState: {
    entities: null,
    isLoading: true,
    error: null,
  },
  reducers: {
    frequenciesRequested: (state) => {
      state.isLoading = true;
    },
    frequenciesRecieved: (state, action) => {
      state.entities = action.payload;
      state.isLoading = false;
    },
    frequenciesRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

const { reducer: frequenciesReducer, actions } = frequenciesSlice;
const { frequenciesRequested, frequenciesRecieved, frequenciesRequestFailed } =
  actions;

export const loadFrequenciesList = () => async (dispatch) => {
  dispatch(frequenciesRequested());
  try {
    const { content } = await frequenciesService.fetchAll();
    dispatch(frequenciesRecieved(content));
  } catch (error) {
    dispatch(frequenciesRequestFailed(error.message));
  }
};

export const getFrequenciesLoadingStatus = () => (state) =>
  state.frequencies.isLoading;
export const getFrequencies = () => (state) => state.frequencies.entities;

export default frequenciesReducer;
