import httpService from "./http.service";
const frequencyEndpoint = "frequency";

const frequenciesService = {
  fetchAll: async () => {
    const { data } = await httpService.get(frequencyEndpoint);
    return data;
  },
};

export default frequenciesService;
