import React from "react";
import { Disclosure, Transition } from "@headlessui/react";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

const FAQsPage = () => {
  return (
    <div className="min-h-screen flex flex-col justify-start">
      <div className="font-[Avenir] border-b-2 border-t-1 text-5xl h-[100px] flex items-center justify-center text-[#13287E]">
        FAQ
      </div>
      <div className="w-full px-4 pt-16 bg-gradient-to-b from-[#FFFFFF] to-[#E1F0F9] min-h-screen h-full">
        <h1 className="text-center text-4xl font-bold mb-[20px] font-[Avenir] normal-case tracking-normal">
          Below is a list of the most frequently asked questions.
        </h1>

        <div className="font-[Avenir] mx-auto w-full h-auto h-full max-w-[800px] rounded-2xl bg-white p-7 border">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center justify-between rounded-lg bg-[#34BDD3] transition duration-300 ease-in-out px-4 py-2 text-left text-2xl font-medium text-white hover:bg-[#239cb0] focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <span>Why Choose Mint Cleaning?</span>
                  <div
                    className={`icon-transition ${
                      open ? "transform rotate-180" : ""
                    }`}
                  >
                    <ChevronDownIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                </Disclosure.Button>
                <Transition
                  show={open}
                  enter="transition duration-300 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-200 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="px-4 pt-4 pb-2 lg:text-lg text-gray-500 font-[Avenir]">
                    Choose Mint Cleaning for a pristine and personalized
                    experience. Our dedicated team and commitment to quality
                    provide reliable and excellent cleaning services to suit
                    your unique needs.
                    <div className="mt-2">
                      Contact us by phone at{" "}
                      <span className="text-emerald-400 font-semibold font-[Avenir]">
                        <a
                          href="tel:9024414854"
                          className="font-semibold font-[Avenir] hover:text-emerald-500 transition ease-in-out duration-200"
                        >
                          (902) 441-4854
                        </a>
                      </span>
                      , or book a service online, call and contact us whenever
                      it is convenient for you, we are here to serve you.
                    </div>
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
          <Disclosure as="div" className="mt-3">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center justify-between rounded-lg bg-[#34BDD3] transition duration-300 ease-in-out px-4 py-2 text-left text-2xl font-medium text-white hover:bg-[#239cb0] focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <span>Are Recurring Cleaning Services Available?</span>
                  <div
                    className={`icon-transition ${
                      open ? "transform rotate-180" : ""
                    }`}
                  >
                    <ChevronDownIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                </Disclosure.Button>
                <Transition
                  show={open}
                  enter="transition duration-300 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-200 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="px-4 pt-4 pb-2 lg:text-lg text-gray-500">
                    Certainly! Customize your cleaning schedule - whether
                    weekly, biweekly, or monthly. Select this preference during
                    booking or update it anytime. Cancelation is hassle - free
                    and can be done at any time.
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
          <Disclosure as="div" className="mt-3">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center justify-between rounded-lg bg-[#34BDD3] transition duration-300 ease-in-out px-4 py-2 text-left text-2xl font-medium text-white hover:bg-[#239cb0] focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <span>How Can I Get A Quote?</span>
                  <div
                    className={`icon-transition ${
                      open ? "transform rotate-180" : ""
                    }`}
                  >
                    <ChevronDownIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                </Disclosure.Button>
                <Transition
                  show={open}
                  enter="transition duration-300 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-200 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="px-4 pt-4 pb-2 lg:text-lg text-gray-500">
                    Visit our{" "}
                    <Link
                      to="/book"
                      className="text-emerald-400 font-semibold font-[Avenir] hover:text-emerald-500 transition ease-in-out duration-200"
                    >
                      Book Now page
                    </Link>{" "}
                    to generate an instant quote based on the size of your home,
                    the type of cleaning. Or visit the{" "}
                    <Link
                      to="/getquote"
                      className="text-emerald-400 font-semibold font-[Avenir] hover:text-emerald-500 transition ease-in-out duration-200"
                    >
                      Get Quote page
                    </Link>{" "}
                    to submit a service inquiry and our friendly team will
                    contact you.
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
          <Disclosure as="div" className="mt-3">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center justify-between rounded-lg bg-[#34BDD3] transition duration-300 ease-in-out px-4 py-2 text-left text-2xl font-medium text-white hover:bg-[#239cb0] focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <span>How do I pay for the service?</span>
                  <div
                    className={`icon-transition ${
                      open ? "transform rotate-180" : ""
                    }`}
                  >
                    <ChevronDownIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                </Disclosure.Button>
                <Transition
                  show={open}
                  enter="transition duration-300 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-200 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="px-4 pt-4 pb-2 lg:text-lg text-gray-500">
                    Feel free to input your credit card information securely
                    either during the online booking process. Rest assured that
                    your card will only be charged once the cleaning is
                    finished.
                    <div className="mt-2">
                      If you make a reservation by phone, we will ask you for a
                      deposit when confirming your order and for our safety.
                    </div>
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
          <Disclosure as="div" className="mt-3">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center justify-between rounded-lg bg-[#34BDD3] transition duration-300 ease-in-out px-4 py-2 text-left text-2xl font-medium text-white hover:bg-[#239cb0] focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <span>
                    What is your guarantee of providing quality services?
                  </span>
                  <div
                    className={`icon-transition ${
                      open ? "transform rotate-180" : ""
                    }`}
                  >
                    <ChevronDownIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                </Disclosure.Button>
                <Transition
                  show={open}
                  enter="transition duration-300 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-200 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="px-4 pt-4 pb-2 lg:text-lg text-gray-500">
                    We ensure your complete satisfaction with our cleaning
                    services. Our diligent and honest cleaners are dedicated to
                    delivering high-quality results, taking their
                    responsibilities seriously. To achieve the desired level of
                    cleanliness, it's crucial to realistically assess the
                    required cleaning hours. Good cleaning is time-intensive,
                    and depending on the requested details, it may exceed the
                    initial booking duration. Clients are urged to meet with our
                    cleaners upon arrival, conduct a property walkthrough, and
                    communicate specific expectations. Returning before
                    completion allows for authorization of extra time if needed.
                    In the rare event of dissatisfaction, please contact us
                    within 24 hours for resolution. Your satisfaction is our
                    utmost priority.
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
          <Disclosure as="div" className="mt-3">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center justify-between rounded-lg bg-[#34BDD3] transition duration-300 ease-in-out px-4 py-2 text-left text-2xl font-medium text-white hover:bg-[#239cb0] focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <span>What if I have a pet?</span>
                  <div
                    className={`icon-transition ${
                      open ? "transform rotate-180" : ""
                    }`}
                  >
                    <ChevronDownIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                </Disclosure.Button>
                <Transition
                  show={open}
                  enter="transition duration-300 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-200 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="px-4 pt-4 pb-2 lg:text-lg text-gray-500">
                    If you have a pet, rest assured that we are pet-friendly!
                    Our professional cleaners are accustomed to working in homes
                    with pets and will take the necessary precautions to ensure
                    their safety and well-being during the cleaning process. If
                    your pet has any specific needs or preferences, please
                    inform us in advance, and we'll do our best to accommodate
                    them.
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
          <Disclosure as="div" className="mt-3">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center justify-between rounded-lg bg-[#34BDD3] transition duration-300 ease-in-out px-4 py-2 text-left text-2xl font-medium text-white hover:bg-[#239cb0] focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <span>
                    What is Deep Cleaning & Move-In / Move-Out Cleaning?
                  </span>
                  <div
                    className={`icon-transition ${
                      open ? "transform rotate-180" : ""
                    }`}
                  >
                    <ChevronDownIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                </Disclosure.Button>
                <Transition
                  show={open}
                  enter="transition duration-300 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-200 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="px-4 pt-4 pb-2 lg:text-lg text-gray-500">
                    <Link
                      to="/services/deep-cleaning"
                      className="underline hover:opacity-80 transition ease-in-out duration-200"
                    >
                      Deep cleaning
                    </Link>{" "}
                    is required when a property hasn’t been cleaned in a while
                    and needs to be brought back up to a high standard of
                    cleanliness. These cleanings often take more time.{" "}
                    <Link
                      to="/services/move-in-move-out-cleaning"
                      className="underline hover:opacity-80 transition ease-in-out duration-200"
                    >
                      Move-In / Move-Out
                    </Link>{" "}
                    cleaning is a deep cleaning that occurs between occupants.
                    We understand the importance of doing a full scale deep
                    cleaning so the new homeowners can enjoy a sparkling clean
                    environment with no sign of the previous occupants.
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
          <Disclosure as="div" className="mt-3">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center justify-between rounded-lg bg-[#34BDD3] transition duration-300 ease-in-out px-4 py-2 text-left text-2xl font-medium text-white hover:bg-[#239cb0] focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <span>What don’t we Clean?</span>
                  <div
                    className={`icon-transition ${
                      open ? "transform rotate-180" : ""
                    }`}
                  >
                    <ChevronDownIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                </Disclosure.Button>
                <Transition
                  show={open}
                  enter="transition duration-300 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-200 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="px-4 pt-4 pb-2 lg:text-lg text-gray-500">
                    <p className="mb-2">
                      We can load the dishwasher, make your beds with clean
                      sheets, and clean accessible areas.
                    </p>
                    <p className="mb-2">
                      If you have high ceilings or hard to reach areas, please
                      let us know during the booking process and provide photos
                      so we can adequately accommodate your cleaning needs.
                    </p>
                    <p>
                      We refrain from cleaning human or pet waste, body fluids,
                      vermin infestations, exterior windows, unsafe balconies,
                      or any areas inaccessible with a stool.
                    </p>
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
          <Disclosure as="div" className="mt-3">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center justify-between rounded-lg bg-[#34BDD3] transition duration-300 ease-in-out px-4 py-2 text-left text-2xl font-medium text-white hover:bg-[#239cb0] focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <span>How Do I Change or Cancel My Booking?</span>
                  <div
                    className={`icon-transition ${
                      open ? "transform rotate-180" : ""
                    }`}
                  >
                    <ChevronDownIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                </Disclosure.Button>
                <Transition
                  show={open}
                  enter="transition duration-300 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-200 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="px-4 pt-4 pb-2 lg:text-lg text-gray-500">
                    Simply contact us by phone call, text message or email to
                    cancel your service or change your service date or time, we
                    will be happy to assist you with this.
                    <p className="mt-2">
                      To avoid a $50 cancellation fee, kindly cancel your
                      scheduled cleaning at least 24 hours in advance.
                    </p>
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>

          <Disclosure as="div" className="mt-3">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center justify-between rounded-lg bg-[#34BDD3] transition duration-300 ease-in-out px-4 py-2 text-left text-2xl font-medium text-white hover:bg-[#239cb0] focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <span>
                    If I book a recurring service, will I get the same cleaner?{" "}
                  </span>
                  <div
                    className={`icon-transition ${
                      open ? "transform rotate-180" : ""
                    }`}
                  >
                    <ChevronDownIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                </Disclosure.Button>
                <Transition
                  show={open}
                  enter="transition duration-300 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-200 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="px-4 pt-4 pb-2 lg:text-lg text-gray-500">
                    Yes! If you wish, we can send the same cleaner as they are
                    familiar with your home and you are comfortable with them.
                    We will always give you first priority over booking repeat
                    appointments with your favourite cleaners.
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
          <Disclosure as="div" className="mt-3">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center justify-between rounded-lg bg-[#34BDD3] transition duration-300 ease-in-out px-4 py-2 text-left text-2xl font-medium text-white hover:bg-[#239cb0] focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <span>How Can I Arrange for Partial Cleaning Services?</span>
                  <div
                    className={`icon-transition ${
                      open ? "transform rotate-180" : ""
                    }`}
                  >
                    <ChevronDownIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                </Disclosure.Button>
                <Transition
                  show={open}
                  enter="transition duration-300 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-200 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="px-4 pt-4 pb-2 lg:text-lg text-gray-500">
                    Perhaps you only require specific areas of your home cleaned
                    — such as deep cleaning for appliances or focusing on a few
                    bathrooms. In such instances, an hourly booking is ideal to
                    address your specific cleaning needs.
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
          <Disclosure as="div" className="mt-3">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center justify-between rounded-lg bg-[#34BDD3] transition duration-300 ease-in-out px-4 py-2 text-left text-2xl font-medium text-white hover:bg-[#239cb0] focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <span>Is it Possible to Clean when I’m not Home</span>
                  <div
                    className={`icon-transition ${
                      open ? "transform rotate-180" : ""
                    }`}
                  >
                    <ChevronDownIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                </Disclosure.Button>
                <Transition
                  show={open}
                  enter="transition duration-300 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-200 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="px-4 pt-4 pb-2 lg:text-lg text-gray-500">
                    In cases where you cannot be present, please provide
                    detailed instructions on your cleaning priorities.
                    Additionally, share access information, such as a lockbox
                    code or instructions for front desk coordination.
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        </div>
        <div className="mt-[30px] opacity-0">1</div>
      </div>
    </div>
  );
};

export default FAQsPage;
