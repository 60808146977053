import React, { useEffect, useState, useCallback } from "react";
import TextField from "../../textField";
import OptionField from "../../optionField";
import { validator } from "../../../utils/validator";
import { CalendarDaysIcon } from "@heroicons/react/20/solid";
import { BiRefresh } from "react-icons/bi";
import {
  bedroomPrices,
  bathroomPrices,
  halfbathroomPrices,
  hourlyPrices,
  deepCleaningPrices,
  windowsPrices,
} from "../../../utils/roomsPrices";
import extrasService from "../../../services/extras.service";
import { useSelector } from "react-redux";
import { getExtras, getExtrasLoadingStatus } from "../../../store/extras";
import { ImSpinner2 } from "react-icons/im";
import {
  getFrequencies,
  getFrequenciesLoadingStatus,
} from "../../../store/frequency";
import { bookNow } from "../../../store/booking";
import { useNavigate } from "react-router-dom";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import PaymentForm from "../../payment/paymentForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import payment from "../../../../assets/payment.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PUBLIC_KEY =
  "pk_live_51O4rhRAUh9Miw2qvW3I6xmS0pdqM4g7Nvu6QILU5GKXSQft97WN3Y11PnulmMROvuaYt7xxEBKlYu4HGjBlAB1Co00GNIBiGB9";

const stripePromise = loadStripe(PUBLIC_KEY);

const BookingForm = () => {
  const extrasData = useSelector(getExtras());
  const frequencyData = useSelector(getFrequencies());
  const frequenciesLoadingStatus = useSelector(getFrequenciesLoadingStatus());
  const extrasLoadingStatus = useSelector(getExtrasLoadingStatus());
  const [extrasList, setExtrasList] = useState([]);
  const [totalExtrasList, setTotalExtrasList] = useState([]);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [frequency, setFrequency] = useState(["One Time"]);
  const navigate = useNavigate();
  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    newclient: "No",
    address: "",
    aptsuit: "",
    city: "",
    postalcode: "",
    typeofcleaning: "House Cleaning",
    bedroom: "One Bedroom",
    fullbathroom: "1 Full Bathroom",
    halfbathroom: "0 Half Bathroom",
    cleaners: "",
    hourly: "",
    date: "",
    time: "",
    pets: "No",
    additionalinfo: "",
    tip: "",
    price: 0,
  });

  const [errors, setErrors] = useState({});
  const [totalBedroomPrice, setTotalBedroomPrice] = useState(135);
  const [totalFullBathroomPrice, setTotalFullBathroomPrice] = useState(30);
  const [totalHalfBathroomPrice, setTotalHalfBathroomPrice] = useState(0);
  const [totalHourlyPrice, setTotalHourlyPrice] = useState(0);
  const [extrasPrice, setExtrasPrice] = useState(0);

  const stripe = useStripe();
  const elements = useElements();

  const [isCardValid, setIsCardValid] = useState(false);
  const handleCardChange = (event) => {
    setIsCardValid(event.complete);
  };

  const numberPattern = /^[0-9]+$/;

  const totalPrice =
    totalBedroomPrice +
    totalFullBathroomPrice +
    totalHalfBathroomPrice +
    totalHourlyPrice;

  /* Total Price Section */
  const totalAndExtrasPrice = totalPrice + extrasPrice;
  const newClientSale = (totalAndExtrasPrice / 100) * 20;
  const priceIfNewClient = totalAndExtrasPrice - newClientSale;
  /* Total Price Section */

  /* Frequecny Price Section */
  const saleWithFrequencyForNewClient =
    frequency[1] !== undefined ? (priceIfNewClient / 100) * frequency[1] : "";
  const saleWithFrequency =
    frequency[1] !== undefined
      ? (totalAndExtrasPrice / 100) * frequency[1]
      : "";
  /* Frequecny Price Section */

  /* Tax Section */
  const salesTaxIfNewClient = (priceIfNewClient / 100) * 15;
  const salesTaxIfNewClientAndFrequency =
    ((priceIfNewClient - saleWithFrequencyForNewClient) / 100) * 15;
  const salesTax = (totalAndExtrasPrice / 100) * 15;
  const salesTaxWithFrequency =
    ((totalAndExtrasPrice - saleWithFrequency) / 100) * 15;
  /* Tax Section */

  function totalAmount(frequency) {
    /* Total Price Section */
    const totalAndExtrasPrice = totalPrice + extrasPrice;
    const newClientSale = (totalAndExtrasPrice / 100) * 20;
    const priceIfNewClient = totalAndExtrasPrice - newClientSale;
    const tip = Number(data.tip);

    /* Total Price Section */

    /* Frequecny Price Section */
    const saleWithFrequencyForNewClient =
      frequency[1] !== undefined ? (priceIfNewClient / 100) * frequency[1] : "";
    const saleWithFrequency =
      frequency[1] !== undefined
        ? (totalAndExtrasPrice / 100) * frequency[1]
        : "";
    /* Frequecny Price Section */

    /* Tax Section */
    const salesTaxIfNewClient = (priceIfNewClient / 100) * 15;
    const salesTaxIfNewClientAndFrequency =
      ((priceIfNewClient - saleWithFrequencyForNewClient) / 100) * 15;
    const salesTax = (totalAndExtrasPrice / 100) * 15;
    const salesTaxWithFrequency =
      ((totalAndExtrasPrice - saleWithFrequency) / 100) * 15;
    /* Tax Section */

    if (data.newclient === "Yes" && frequency[1] !== undefined) {
      const total =
        priceIfNewClient -
        saleWithFrequencyForNewClient +
        salesTaxIfNewClientAndFrequency;
      return total + tip;
    } else if (data.newclient === "Yes") {
      const total = priceIfNewClient + salesTaxIfNewClient;
      return total + tip;
    } else if (data.newclient === "No" && frequency[1] !== undefined) {
      const total =
        totalAndExtrasPrice - saleWithFrequency + salesTaxWithFrequency;
      return total + tip;
    } else if (data.newclient === "No") {
      const total = totalAndExtrasPrice + salesTax;
      return total + tip;
    }
  }

  const finalPrice = parseFloat(totalAmount(frequency)).toFixed(2);

  const initExtras = () => {
    extrasService.fetchAll();
  };

  useEffect(() => {
    initExtras();
  }, []);

  const addToExtras = (extra) => {
    setExtrasList((prevState) => [...prevState, extra.name]);
    if (extra.name.includes("Deep Cleaning")) {
      setTotalExtrasList((prevState) => [
        ...prevState,
        [extra._id, extra.name, deepCleaningPrices[data.bedroom]],
      ]);
    } else if (extra.name.includes("Windows (interior)")) {
      setTotalExtrasList((prevState) => [
        ...prevState,
        [extra._id, extra.name, windowsPrices[data.bedroom]],
      ]);
    } else {
      setTotalExtrasList((prevState) => [
        ...prevState,
        [extra._id, extra.name, extra.price],
      ]);
    }

    if (extra.name.includes("Deep Cleaning")) {
      setExtrasPrice(
        (prevState) => prevState + deepCleaningPrices[data.bedroom]
      );
    } else if (extra.name.includes("Windows (interior)")) {
      setExtrasPrice((prevState) => prevState + windowsPrices[data.bedroom]);
    } else {
      setExtrasPrice((prevState) => prevState + extra.price);
    }
  };

  const removeFromExtras = (extra) => {
    setExtrasList(extrasList.filter((el) => el !== extra.name));
    setTotalExtrasList(totalExtrasList.filter((el) => el[1] !== extra.name));

    if (extra.name === "Deep Cleaning") {
      setExtrasPrice(
        (prevState) => prevState - deepCleaningPrices[data.bedroom]
      );
    } else if (extra.name === "Windows (interior)") {
      setExtrasPrice((prevState) => prevState - windowsPrices[data.bedroom]);
    } else setExtrasPrice((prevState) => prevState - extra.price);
  };

  const chooseFrequency = (name, sale) => {
    setFrequency([name, sale]);
  };

  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      frequency: frequency,
    }));
  }, [frequency]);

  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      price: finalPrice,
    }));
  }, [finalPrice]);

  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      extras: extrasList,
    }));
  }, [extrasList]);

  const validatorConfig = {
    firstname: { isRequired: { message: "First Name is required" } },
    lastname: { isRequired: { message: "Last Name is required" } },
    email: {
      isRequired: { message: "Email is required" },
      isEmail: { message: "Email entered incorrectly" },
    },
    phone: { isRequired: { message: "Phone is required" } },
    address: { isRequired: { message: "Address is required" } },
    city: { isRequired: { message: "City is required" } },
    postalcode: { isRequired: { message: "Postal Code is required" } },
    date: { isRequired: { message: "Date is required" } },
    time: { isRequired: { message: "Time is required" } },
  };

  const validate = useCallback((data) => {
    const errors = validator(data, validatorConfig);
    setErrors(errors);
    return Object.keys(errors).length === 0;
  }, []);

  const isValid = Object.keys(errors).length === 0;

  const handleChange = ({ target }) => {
    setData((prevState) => {
      return { ...prevState, [target.name]: target.value };
    });
  };

  const handleChangePrice = ({ target }) => {
    const selectedRooms = target.value;
    if (selectedRooms.includes("Bedroom")) {
      setTotalBedroomPrice(bedroomPrices[selectedRooms]);
      setTotalHourlyPrice(0);
      setTotalExtrasList([]);
      setExtrasList([]);
      setExtrasPrice(0);
    } else if (selectedRooms.includes("Full Bathroom")) {
      setTotalFullBathroomPrice(bathroomPrices[selectedRooms]);
      setTotalHourlyPrice(0);
    } else if (selectedRooms.includes("Full Bathrooms")) {
      setTotalFullBathroomPrice(bathroomPrices[selectedRooms]);
      setTotalHourlyPrice(0);
    } else if (selectedRooms.includes("Half Bathroom")) {
      setTotalHalfBathroomPrice(halfbathroomPrices[selectedRooms]);
      setTotalHourlyPrice(0);
    } else if (selectedRooms.includes("Half Bathrooms")) {
      setTotalHalfBathroomPrice(halfbathroomPrices[selectedRooms]);
      setTotalHourlyPrice(0);
    } else if (selectedRooms.includes("Hours")) {
      setTotalHourlyPrice(hourlyPrices[selectedRooms]);
      setTotalBedroomPrice(0);
      setTotalFullBathroomPrice(0);
      setTotalHalfBathroomPrice(0);
    } else if (selectedRooms.includes("Hour")) {
      setTotalHourlyPrice(hourlyPrices[selectedRooms]);
      setTotalBedroomPrice(0);
      setTotalFullBathroomPrice(0);
      setTotalHalfBathroomPrice(0);
    }

    setData((prevState) => {
      return { ...prevState, [target.name]: target.value };
    });
  };

  const handleChangeForTypeCleaning = ({ target }) => {
    const selectedType = target.value;

    if (selectedType === "House Cleaning") {
      setData((prevState) => {
        return {
          ...prevState,
          cleaners: "",
          hourly: "",
          bedroom: "One Bedroom",
          fullbathroom: "1 Full Bathroom",
        };
      });
      setTotalHourlyPrice(0);
      setTotalBedroomPrice(135);
      setTotalFullBathroomPrice(30);
      setTotalHalfBathroomPrice(0);
      setTotalExtrasList([]);
      setExtrasList([]);
      setExtrasPrice(0);
    } else if (selectedType === "Moving Cleaning") {
      setData((prevState) => {
        return {
          ...prevState,
          cleaners: "",
          hourly: "",
          bedroom: "One Bedroom",
          fullbathroom: "1 Full Bathroom",
        };
      });
      setTotalHourlyPrice(0);
      setTotalBedroomPrice(135);
      setTotalFullBathroomPrice(30);
      setTotalHalfBathroomPrice(0);
      setTotalExtrasList([]);
      setExtrasList([]);
      setExtrasPrice(0);
    } else if (selectedType === "Airbnb") {
      setData((prevState) => {
        return {
          ...prevState,
          bedroom: "",
          fullbathroom: "",
          halfbathroom: "",
          cleaners: "2 Cleaners",
          hourly: "2 Hours",
        };
      });
      setTotalBedroomPrice(0);
      setTotalFullBathroomPrice(0);
      setTotalHalfBathroomPrice(0);
      setTotalHourlyPrice(160);
      setTotalExtrasList([]);
      setExtrasList([]);
      setExtrasPrice(0);
    } else if (selectedType === "Hourly") {
      setData((prevState) => {
        return {
          ...prevState,
          bedroom: "",
          fullbathroom: "",
          halfbathroom: "",
          cleaners: "2 Cleaners",
          hourly: "2 Hours",
        };
      });
      setTotalBedroomPrice(0);
      setTotalFullBathroomPrice(0);
      setTotalHalfBathroomPrice(0);
      setTotalHourlyPrice(160);
      setTotalExtrasList([]);
      setExtrasList([]);
      setExtrasPrice(0);
    }

    setData((prevState) => {
      return { ...prevState, [target.name]: target.value };
    });
  };

  const price = finalPrice.toString().replace(".", "");

  const handleSubmit = async (e) => {
    setButtonClicked(true);

    e.preventDefault();
    const isValid = validate(data);
    if (!isValid) return;
    if (!stripe || !elements) return;
    if (!isCardValid) return;
    if (
      data.bedroom === "0 Bedroom" &&
      data.fullbathroom === "0 Full Bathroom" &&
      data.hourly === ""
    )
      return;
    setData((prevState) => {
      return { ...prevState, price: finalPrice };
    });

    const { token, error } = await stripe.createToken(
      elements.getElement(CardElement)
    );

    if (Number(data.tip) < 0 || !numberPattern.test(Number(data.tip))) {
      toast.error(`Tip: Please enter numbers only without symbols`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
      return;
    }

    if (error) {
      // console.error(`Ошибка при создании токена карты: ${error.message}`);
      toast.error(`Payment authorization error`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
      return;
    }

    // Отправка запроса на авторизацию платежа с использованием токена и суммы
    const response = await fetch("https://mint-cleaning.ca/payment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token.id,
        amount: price,
        email: data.email,
        name: data.firstname + " " + data.lastname,
      }),
    });

    if (!response.ok) {
      console.error(`Payment authorization error: ${response.statusText}`);
      toast.error(`Payment authorization error. Please try again`, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: 0,
        progressStyle: { background: "#fffff" },
        theme: "colored",
        style: { background: "#db0000" },
      });
      return;
    }

    try {
      const result = await response.json();

      if (result.success) {
        bookNow(data);
        navigate("/booked");
      } else {
        toast.error(`Payment authorization error`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 4000,
        });
        // console.error(`Payment authorization error: ${result.error}`);
      }
    } catch (error) {
      console.error(`Error parsing server response: ${error.message}`);
      toast.error(`Error parsing server response`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 4000,
      });
    }
  };

  // const [isCounterOpen, setIsCounterOpen] = useState(false);
  // const counterRef = useRef(null);

  // const handleIncrement = () => {
  //   if (curtainCount < 10) {
  //     setCurtainCount(curtainCount + 1);
  //   }
  // };

  // const handleDecrement = () => {
  //   if (curtainCount > 0) {
  //     setCurtainCount(curtainCount - 1);
  //   }
  // };

  // const handleClickOutside = (event) => {
  //   if (
  //     counterRef.current &&
  //     !counterRef.current.contains(event.target) &&
  //     !event.target.classList.contains("counter-button")
  //   ) {
  //     setIsCounterOpen(false);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);

  useEffect(() => {
    if (buttonClicked) {
      validate(data);
    }
  }, [data, buttonClicked]);

  if (extrasLoadingStatus || frequenciesLoadingStatus)
    return (
      <ImSpinner2 className="mx-auto animate-spin text-[#34BDD3] text-4xl mt-[200px]" />
    );

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-8">
        <div className="text-center font-light mt-[30px] border-b border-gray-300 mx-[20px]">
          <p className="text-4xl ">Complete your booking</p>
          <p className="text-xl mt-[20px] mb-[20px]">
            Great! Few details and we can complete your booking
          </p>
        </div>
        <div className="border-b border-gray-300 mx-[20px]">
          <div className="font-[Avenir] mx-auto max-w-[800px] pb-9">
            <h2 className="text-base font-semibold leading-7 text-gray-900 uppercase">
              Contact Info
            </h2>
            <p className="mt-1 text-base leading-6 text-gray-500">
              This information will be used to contact you about your service.
            </p>
            <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
              <TextField
                type="text"
                name="firstname"
                placeholder="First Name*"
                value={data.firstname}
                onChange={handleChange}
                error={errors.firstname}
                className1="sm:col-span-3"
                className2="block text-sm font-medium leading-6 text-gray-900"
                className3="mt-2"
              />
              <TextField
                type="text"
                name="lastname"
                placeholder="Last Name*"
                value={data.lastname}
                onChange={handleChange}
                error={errors.lastname}
                className1="sm:col-span-3"
                className2="block text-sm font-medium leading-6 text-gray-900"
                className3="mt-2"
              />
              <TextField
                type="text"
                name="email"
                placeholder="Email*"
                value={data.email}
                onChange={handleChange}
                error={errors.email}
                className1="sm:col-span-3"
                className2="block text-sm font-medium leading-6 text-gray-900"
                className3="mt-2"
              />
              <TextField
                type="text"
                name="phone"
                placeholder="Phone*"
                value={data.phone}
                onChange={handleChange}
                error={errors.phone}
                className1="sm:col-span-3"
                className2="block text-sm font-medium leading-6 text-gray-900"
                className3="mt-2"
              />
            </div>
          </div>
        </div>

        {/* <div className="border-b border-gray-300 mx-[20px]">
          <div className="mx-auto font-[Avenir] max-w-[800px] pb-9">
            <h2 className="text-base font-semibold leading-7 text-gray-900 uppercase">
              Info
            </h2>
            <div className="mt-3">
              <div className="sm:col-span-3">
                <label
                  htmlFor="newclient"
                  className="block text-base font-medium leading-6 text-gray-500"
                >
                  Are you a new client?
                </label>
                <div className="mt-4">
                  <select
                    id="newclient"
                    name="newclient"
                    value={data.newclient}
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#71d1a7] sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option>No</option>
                    <option>Yes</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="border-b border-gray-300 mx-[20px]">
          <div className="mx-auto font-[Avenir] max-w-[800px]  pb-9">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              YOUR HOME
            </h2>
            <p className="mt-1 text-base leading-6 text-gray-500">
              Where would you like the cleaning?
            </p>
            <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
              <TextField
                type="text"
                name="address"
                placeholder="Address*"
                value={data.address}
                onChange={handleChange}
                error={errors.address}
                className1="sm:col-span-3"
                className2="block text-sm font-medium leading-6 text-gray-900"
                className3="mt-2"
              />
              <TextField
                type="text"
                name="aptsuit"
                placeholder="Apt/Suite #"
                value={data.aptsuit}
                onChange={handleChange}
                error={errors.aptsuit}
                className1="sm:col-span-3"
                className2="block text-sm font-medium leading-6 text-gray-900"
                className3="mt-2"
              />
              <TextField
                type="text"
                name="city"
                placeholder="City*"
                value={data.city}
                onChange={handleChange}
                error={errors.city}
                className1="sm:col-span-3"
                className2="block text-sm font-medium leading-6 text-gray-900"
                className3="mt-2"
              />
              <TextField
                type="text"
                name="postalcode"
                placeholder="Postal Code*"
                value={data.postalcode}
                onChange={handleChange}
                error={errors.postalcode}
                className1="sm:col-span-3"
                className2="block text-sm font-medium leading-6 text-gray-900"
                className3="mt-2"
              />
            </div>
          </div>
        </div>

        <div className="border-b border-gray-300 mx-[20px]">
          <div className="mx-auto font-[Avenir] max-w-[800px]  pb-9">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              TYPE OF CLEANING
            </h2>
            <p className="mt-1 text-base leading-6 text-gray-500">
              Please select the type of cleaning that suits you
            </p>
            <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8">
              <OptionField
                name="typeofcleaning"
                value={data.typeofcleaning}
                onChange={handleChangeForTypeCleaning}
                className1="sm:col-span-3"
                className2="mt-2"
                className3="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#71d1a7] sm:text-sm sm:leading-6"
                option1="House Cleaning"
                option2="Moving Cleaning"
                option3="Hourly"
                option4="Airbnb"
              />
            </div>
          </div>
        </div>

        <div className="border-b border-gray-300 mx-[20px]">
          <div className="mx-auto font-[Avenir] max-w-[800px] pb-6">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              CHOOSE YOUR SERVICE
            </h2>
            <div className="text-gray-500">
              <p className="mt-1 text-base leading-6">Describe your home.</p>
              <p>
                - Indicate only those bedrooms that need to be cleaned (Partial
                cleans must be booked Hourly)
              </p>
              <p>
                - Any extra room, bonus room, office room, etc is considered as
                an additional bedroom.
              </p>
              <p>
                - This price is assuming 1 kitchen, if you have more than 1
                kitchen, please contact us.
              </p>
            </div>
            <div
              className={
                data.typeofcleaning === "Airbnb" ||
                data.typeofcleaning === "Hourly"
                  ? "mt-6 grid grid-cols-1 gap-x-6 gap-y-8"
                  : "mt-6 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-3"
              }
            >
              {data.typeofcleaning === "Airbnb" ||
              data.typeofcleaning === "Hourly" ? (
                <OptionField
                  name="typeofcleaning"
                  value={data.typeofcleaning}
                  className1="sm:col-span-3"
                  className2="mt-2"
                  className3="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#71d1a7] sm:text-sm sm:leading-6"
                  option1={data.typeofcleaning}
                />
              ) : (
                <OptionField
                  name="bedroom"
                  value={data.bedroom}
                  onChange={handleChangePrice}
                  className1="sm:col-span-3"
                  className2="mt-2"
                  className3="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#71d1a7] sm:text-sm sm:leading-6"
                  option1="One Bedroom"
                  option2="Two Bedroom"
                  option3="Three Bedroom"
                  option4="Four Bedroom"
                  option5="Five Bedroom"
                  option6="Six Bedroom"
                />
              )}
              {data.typeofcleaning === "Airbnb" ||
              data.typeofcleaning === "Hourly" ? (
                ""
              ) : (
                <OptionField
                  name="fullbathroom"
                  value={data.fullbathroom}
                  onChange={handleChangePrice}
                  className1="sm:col-span-3"
                  className2="mt-2"
                  className3="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#71d1a7] sm:text-sm sm:leading-6"
                  option1="1 Full Bathroom"
                  option2="2 Full Bathrooms"
                  option3="3 Full Bathrooms"
                  option4="4 Full Bathrooms"
                  option5="5 Full Bathrooms"
                  option6="6 Full Bathrooms"
                />
              )}
              {data.typeofcleaning === "Airbnb" ||
              data.typeofcleaning === "Hourly" ? (
                ""
              ) : (
                <OptionField
                  name="halfbathroom"
                  value={data.halfbathroom}
                  onChange={handleChangePrice}
                  className1="sm:col-span-3"
                  className2="mt-2"
                  className3="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#71d1a7] sm:text-sm sm:leading-6"
                  option1="0 Half Bathroom"
                  option2="1 Half Bathroom"
                  option3="2 Half Bathrooms"
                  option4="3 Half Bathrooms"
                  option5="4 Half Bathrooms"
                  option6="5 Half Bathrooms"
                  option7="6 Half Bathrooms"
                />
              )}
            </div>
            {data.bedroom === "Hourly" ||
            data.typeofcleaning === "Airbnb" ||
            data.typeofcleaning === "Hourly" ? (
              <div>
                <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <OptionField
                    name="cleaners"
                    value={data.cleaners}
                    onChange={handleChange}
                    className1="sm:col-span-3"
                    className2="mt-2"
                    className3="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#71d1a7] sm:text-sm sm:leading-6"
                    option1="2 Cleaners"
                  />
                  <OptionField
                    name="hourly"
                    value={data.hourly}
                    onChange={handleChangePrice}
                    className1="sm:col-span-3"
                    className2="mt-2"
                    className3="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#71d1a7] sm:text-sm sm:leading-6"
                    option1="1 Hour"
                    option2="1.5 Hours"
                    option3="2 Hours"
                    option4="2.5 Hours"
                    option5="3 Hours"
                    option6="3.5 Hours"
                    option7="4 Hours"
                    option8="4.5 Hours"
                    option9="5 Hours"
                    option10="5.5 Hours"
                    option11="6 Hours"
                    option12="6.5 Hours"
                    option13="7 Hours"
                    option14="7.5 Hours"
                    option15="8 Hours"
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        {(data.typeofcleaning === "House Cleaning" ||
          data.typeofcleaning === "Moving Cleaning") && (
          <div className="border-b border-gray-300 mx-[20px]">
            <div className="mx-auto font-[Avenir] max-w-[800px]  pb-9">
              <p className="text-base font-semibold leading-7 text-gray-900 tracking-wider">
                SELECT EXTRAS
              </p>
              <p className="text-base leading-6 text-gray-500">
                Please select all extras that you need!
              </p>
              <p className="mt-3 font-semibold text-base leading-6 text-gray-600">
                Appliances and windows come as part of the package in Deep
                Cleans and Moving Cleans.
              </p>
              <p className="mt-3 text-base leading-6 text-gray-500">
                There should not be other workers (movers, construction crew,
                painters) during the cleaning or the cleaning might not be
                perfect.
              </p>

              <div className="mt-4">
                <div className="sm:col-span-3">
                  <div className="mt-2 grid grid-cols-3 md:grid-cols-4 gap-6">
                    {extrasData.map((e) => (
                      <div
                        key={e._id}
                        className="max-w-[150px] w-full relative"
                      >
                        <button
                          onClick={
                            extrasList.includes(e.name)
                              ? () => removeFromExtras(e)
                              : () => addToExtras(e)
                          }
                          type="button"
                          className={
                            "max-w-[150px] w-full transition duration-700 ease-out hover:cursor-pointer hover:border-orange-400 border rounded-md flex items-center justify-center " +
                            (extrasList.includes(e.name)
                              ? "border-orange-400 border-2"
                              : "border-gray-300")
                          }
                        >
                          <img
                            src={e.image}
                            className="w-[70px] h-[70px] px-1 py-1 max-md:px-2 max-md:py-2"
                          />
                        </button>
                        <p className="text-sm text-center mt-1">{e.name}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="border-b border-gray-300 mx-[20px]">
          <div className="mx-auto font-[Avenir] max-w-[800px]  pb-9">
            <h2 className="text-base font-semibold leading-7 text-gray-900 uppercase">
              When would you like your cleaners to come?
            </h2>
            <p className="mt-1 text-base leading-6 text-gray-500">
              Please indicate the desired date and time for the cleaners to
              arrive
            </p>

            <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <TextField
                type="text"
                name="date"
                placeholder="Date*"
                value={data.date}
                onChange={handleChange}
                error={errors.date}
                className1="sm:col-span-3"
                className2="block text-sm font-medium leading-6 text-gray-900"
                className3="mt-2"
              />
              <TextField
                type="text"
                name="time"
                placeholder="Time*"
                value={data.time}
                onChange={handleChange}
                error={errors.time}
                className1="sm:col-span-3"
                className2="block text-sm font-medium leading-6 text-gray-900"
                className3="mt-2"
              />
            </div>
          </div>
        </div>

        <div className="border-b border-gray-300 mx-[20px]">
          <div className="mx-auto font-[Avenir] max-w-[800px]  pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900 uppercase">
              How often?
            </h2>
            <p className="mt-1 text-base leading-6 text-gray-500">
              Hourly bookings do not qualify for frequency discounts.
            </p>
            <div className="mt-6">
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-5 text-sm">
                {frequencyData.map((f) => (
                  <button
                    key={f._id}
                    type="button"
                    onClick={() => chooseFrequency(f.name, f.sale)}
                    className={
                      "border px-4 py-2 rounded-md hover:border-orange-400 transition duration-200 ease-in " +
                      (frequency.includes(f.name)
                        ? "bg-orange-400 text-white"
                        : "border-gray-300")
                    }
                  >
                    {f.name}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="border-b border-gray-300 mx-[20px]">
          <div className="mx-auto font-[Avenir] max-w-[800px] pb-9">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              INFO
            </h2>
            <div className="mt-3">
              <div className="sm:col-span-3">
                <label
                  htmlFor="pets"
                  className="block text-base font-medium leading-6 text-gray-500"
                >
                  Do you have pets?
                </label>
                <div className="mt-4">
                  <select
                    id="pets"
                    name="pets"
                    value={data.pets}
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#71d1a7] sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option>No</option>
                    <option>Yes</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="border-b border-gray-300 mx-[20px]">
          <div className="mx-auto font-[Avenir] max-w-[800px] pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900 uppercase">
              Additional Information
            </h2>
            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="col-span-full">
                <label
                  htmlFor="additionalinfo"
                  className="block text-base font-medium leading-6 text-gray-500"
                >
                  Anything else your cleaners should know about?
                </label>
                <div className="mt-2">
                  <textarea
                    id="additionalinfo"
                    name="additionalinfo"
                    placeholder="Do you have any special requests or anything else you would like us to know? Please let us know how to enter the property, where we may dispose of the garbage"
                    rows={3}
                    value={data.additionalinfo}
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 placeholder:text-sm md:placeholder:text-base focus:ring-2 focus:ring-inset focus:ring-[#71d1a7] sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="border-b border-gray-300 mx-[20px]">
          <div className="mx-auto font-[Avenir] max-w-[800px] pb-12">
            <h2 className="text-base font-semibold text-gray-900 uppercase">
              If you wish, you can leave a tip here
            </h2>
            <div className="mt-5">
              <div className="sm:col-span-3">
                <div className="mt-2">
                  <input
                    type="text"
                    name="tip"
                    id="tip"
                    placeholder="Tip"
                    value={data.tip}
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 placeholder:text-base focus:ring-2 focus:ring-inset focus:ring-[#71d1a7] sm:text-sm sm:leading-6"
                  />
                  {Number(data.tip) < 0 ||
                  !numberPattern.test(Number(data.tip)) ? (
                    <div className="text-red-500 mt-3 font-[Avenir] text-center">
                      Please enter numbers only without symbols
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="border-b border-gray-300 mx-[20px]">
          <div className="mx-auto font-[Avenir] max-w-[800px] pb-12">
            <h2 className="text-base font-semibold text-gray-900">
              SELECT PAYMENT
            </h2>
            <p className="mt-1 text-base leading-6 text-gray-600">
              Please enter your card information below. We will place a hold on
              your card for the amount of the cleaning and you won't be charged
              until after the cleaning is complete.
            </p>
            <div className="mt-5">
              <PaymentForm onChange={handleCardChange} />
              <div>
                <img src={payment} className="w-[160px] md:w-[250px]" />
              </div>
              <p className="text-sm mt-3 text-gray-400 text-center">
                I authorize Mint Cleaning to charge my credit card above for
                agreed upon purchases. I understand that my information will be
                saved to file for further transactions on my account.
              </p>
              {isCardValid ? (
                ""
              ) : (
                <div className="text-red-500 mt-3 font-[Avenir] text-center">
                  A valid Card Number is required
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex flex-col font-[Avenir] max-w-[800px] mx-auto max-md:mx-5 mb-6 border border-2 rounded-3xl">
        <div className="mt-2 text-orange-400 text-center text-3xl font-semibold border-b-2">
          <p className="mb-3 uppercase tracking-wide max-md:text-2xl">
            Booking Summary
          </p>
        </div>
        <div className="px-7 mt-6 mb-3">
          {data.bedroom !== "0 Bedroom" && totalBedroomPrice !== 0 ? (
            <div className="flex justify-between mb-2">
              <div>{data.bedroom}</div>
              <div>${totalBedroomPrice}.00</div>
            </div>
          ) : (
            ""
          )}
          {data.hourly !== "" ? (
            <div className="flex justify-between mb-2">
              <div>
                {data.cleaners} x {data.hourly}
              </div>
              <div>${totalHourlyPrice}.00</div>
            </div>
          ) : (
            ""
          )}
          {data.fullbathroom !== "0 Full Bathroom" &&
          totalFullBathroomPrice !== 0 ? (
            <div className="flex justify-between mb-2">
              <div>{data.fullbathroom}</div>
              <div>${totalFullBathroomPrice}.00</div>
            </div>
          ) : (
            ""
          )}
          {data.halfbathroom !== "0 Half Bathroom" &&
          totalHalfBathroomPrice !== 0 ? (
            <div className="flex justify-between mb-2">
              <div>{data.halfbathroom}</div>
              <div>${totalHalfBathroomPrice}.00</div>
            </div>
          ) : (
            ""
          )}
        </div>

        {totalExtrasList.length > 0 ? (
          <div className="px-7 mb-3 flex flex-col">
            <p className="border-b-2 mb-1">Extras</p>
            {totalExtrasList.map((extra) => {
              return (
                <div key={extra[0]} className="flex justify-between">
                  <div>{extra[1]}</div>
                  <div>${extra[2]}.00</div>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}

        <div className="border-b-2">
          <div className="flex max-md:flex-col max-md:gap-y-2 px-7 font-semibold gap-x-10 mb-2">
            {data.date === "" ? (
              ""
            ) : (
              <div className="flex items-center">
                <div className="h-7 w-7 md:h-10 md:w-10 text-[#34D399]">
                  <CalendarDaysIcon />
                </div>
                <div className="ml-1">{data.date}</div>
              </div>
            )}
            <div className="flex items-center">
              <div>
                <BiRefresh className="h-7 w-7 md:h-10 md:w-10 text-[#34D399]" />
              </div>
              <div className="ml-1">
                {data.frequency ? data.frequency[0] : ""}
              </div>
            </div>
          </div>
        </div>

        <div className="uppercase mt-4 gap-y-2 flex flex-col text-xl px-7 mb-4">
          <p>
            {data.newclient === "Yes" ? (
              <span>
                {frequency[1] !== undefined
                  ? `Sub-Total With 20% Off $${parseFloat(
                      priceIfNewClient - saleWithFrequencyForNewClient
                    ).toFixed(2)}
                    `
                  : `Sub-Total With 20% Off $${parseFloat(
                      priceIfNewClient
                    ).toFixed(2)}`}
                <p className="text-sm text-gray-400">(New Customer Discount)</p>
              </span>
            ) : (
              <span>
                {frequency[1] !== undefined
                  ? `Sub-Total $${parseFloat(
                      totalAndExtrasPrice - saleWithFrequency
                    ).toFixed(2)}`
                  : `Sub-Total $${parseFloat(totalAndExtrasPrice).toFixed(2)}`}
              </span>
            )}
          </p>

          {data.tip !== "" && numberPattern.test(Number(data.tip))
            ? `Tip $${parseFloat(data.tip).toFixed(2)}`
            : ""}

          <p>
            {data.newclient === "Yes" ? (
              <span>
                {frequency[1] !== undefined
                  ? `Sales Tax $${parseFloat(
                      salesTaxIfNewClientAndFrequency
                    ).toFixed(2)}`
                  : `Sales Tax $${parseFloat(salesTaxIfNewClient).toFixed(2)}`}
              </span>
            ) : (
              <span>
                {frequency[1] !== undefined
                  ? `Sales Tax $${parseFloat(salesTaxWithFrequency).toFixed(2)}`
                  : `Sales Tax $${parseFloat(salesTax).toFixed(2)}`}
              </span>
            )}
          </p>

          {!numberPattern.test(Number(data.tip)) ? (
            ""
          ) : (
            <p className="mb-3 font-semibold">
              Total
              <span className="ml-2 text-emerald-400 ">${finalPrice}</span>
            </p>
          )}

          {data.bedroom === "0 Bedroom" &&
          data.fullbathroom === "0 Full Bathroom" ? (
            <p className="text-red-500 text-lg text-center">
              To complete your booking please select at least one room
            </p>
          ) : (
            ""
          )}
          <p className="text-base text-center font-[Avenir] normal-case text-gray-400">
            By clicking the Book Now button you are agreeing to our Terms &
            Conditions and Privacy Policy.
          </p>
          <button
            type="submit"
            className="mb-1 uppercase tracking-wider rounded-md bg-[#34BDD3] py-3 text-sm font-semibold text-white shadow-sm hover:bg-[#239cb0] transition duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#34BDD3]"
          >
            Book Now
          </button>
          {!isValid ? (
            <p className="text-red-500 text-center text-lg">
              Required fields must be filled in
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
    </form>
  );
};

const CheckoutFormStripe = () => (
  <Elements stripe={stripePromise}>
    <BookingForm />
  </Elements>
);

export default CheckoutFormStripe;
