import React from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getCleaningTypeByServiceType,
  getCleaningsLoadingStatus,
} from "../../../store/cleanings";
import { ImSpinner2 } from "react-icons/im";
import DeepCleaning from "./deepCleaning";
import MovingCleaning from "./movingCleaning";

const CleaningPage = () => {
  const params = useParams();
  const { serviceType } = params;
  const cleaningType = useSelector(getCleaningTypeByServiceType(serviceType));
  const cleaningLoadingStatus = useSelector(getCleaningsLoadingStatus());

  if (cleaningLoadingStatus)
    return (
      <ImSpinner2 className="mx-auto animate-spin text-[#34BDD3] text-4xl mt-[200px]" />
    );

  return (
    <div className="min-h-screen flex flex-col justify-start">
      <div className="mb-[30px]">
        <>
          <div className="text-center relative max-md:mt-4 max-md:px-4">
            <img
              src={cleaningType.smimage}
              className="rounded-[20px] mx-auto md:hidden"
              alt="Small Image"
              width={430}
              height={245}
            />
            <img
              src={cleaningType.image}
              className="md:w-full max-[640px]:h-[140px] max-md:hidden"
              alt="Image"
              width={1920}
              height={485}
            />
            <p className="absolute max-md:hidden lg:text-5xl font-[Avenir] font-bold min-[350px]:left-[190px] md:left-[360px] md:top-[65px] min-[900px]:left-[470px] min-[900px]:top-[70px] md:text-3xl lg:left-[900px] lg:top-[130px] max-w-[450px] break-words">
              {cleaningType.title}
            </p>
          </div>
          <div className="min-[350px]:mt-[25px] lg:mt-[50px]">
            <div className="flex flex-col items-center justify-center">
              <h1 className="min-[350px]:text-xl min-[350px]:text-center lg:text-4xl font-semibold font-[Avenir] normal-case tracking-normal">
                {cleaningType.subtitle}
              </h1>
              {serviceType === "deep-cleaning" ? (
                <div className="font-[Avenir] min-[350px]:text-base lg:text-base mt-[10px] min-[350px]:max-w-[345px] md:max-w-[940px] text-center">
                  {cleaningType.text}
                </div>
              ) : (
                <div className="max-md:px-4 font-[Avenir] min-[350px]:text-base lg:text-base mt-[10px] min-[350px]:max-w-[400px] md:max-w-[700px] text-center">
                  {cleaningType.text}
                </div>
              )}
            </div>
            {serviceType === "move-in-move-out-cleaning" && (
              <MovingCleaning
                service1Image={cleaningType.service1Image}
                service1text={cleaningType.service1text}
                service2Image={cleaningType.service2Image}
                service2text={cleaningType.service2text}
                service3Image={cleaningType.service3Image}
                service3text={cleaningType.service3text}
                service4Image={cleaningType.service4Image}
                service4text={cleaningType.service4text}
                service5Image={cleaningType.service5Image}
                service5text={cleaningType.service5text}
                service6Image={cleaningType.service6Image}
                service6text={cleaningType.service6text}
                service7Image={cleaningType.service7Image}
                service7text={cleaningType.service7text}
                service8Image={cleaningType.service8Image}
                service8text={cleaningType.service8text}
                service9Image={cleaningType.service9Image}
                service9text={cleaningType.service9text}
                service10Image={cleaningType.service10Image}
                service10text={cleaningType.service10text}
                service11Image={cleaningType.service11Image}
                service11text={cleaningType.service11text}
                service12Image={cleaningType.service12Image}
                service12text={cleaningType.service12text}
                service13Image={cleaningType.service13Image}
                service13text={cleaningType.service13text}
                service14Image={cleaningType.service14Image}
                service14text={cleaningType.service14text}
              />
            )}
            {serviceType === "deep-cleaning" && (
              <DeepCleaning
                service1Image={cleaningType.service1Image}
                service1text={cleaningType.service1text}
                service2Image={cleaningType.service2Image}
                service2text={cleaningType.service2text}
                service3Image={cleaningType.service3Image}
                service3text={cleaningType.service3text}
                service4Image={cleaningType.service4Image}
                service4text={cleaningType.service4text}
                service5Image={cleaningType.service5Image}
                service5text={cleaningType.service5text}
                service6Image={cleaningType.service6Image}
                service6text={cleaningType.service6text}
                service7Image={cleaningType.service7Image}
                service7text={cleaningType.service7text}
                service8Image={cleaningType.service8Image}
                service8text={cleaningType.service8text}
                service9Image={cleaningType.service9Image}
                service9text={cleaningType.service9text}
                service10Image={cleaningType.service10Image}
                service10text={cleaningType.service10text}
                service11Image={cleaningType.service11Image}
                service11text={cleaningType.service11text}
                service12Image={cleaningType.service12Image}
                service12text={cleaningType.service12text}
                service13Image={cleaningType.service13Image}
                service13text={cleaningType.service13text}
                service14Image={cleaningType.service14Image}
                service14text={cleaningType.service14text}
                service15Image={cleaningType.service15Image}
                service15text={cleaningType.service15text}
                service16Image={cleaningType.service16Image}
                service16text={cleaningType.service16text}
                service17Image={cleaningType.service17Image}
                service17text={cleaningType.service17text}
                service18Image={cleaningType.service18Image}
                service18text={cleaningType.service18text}
                service19Image={cleaningType.service19Image}
                service19text={cleaningType.service19text}
              />
            )}
            {serviceType !== "deep-cleaning" &&
              serviceType !== "move-in-move-out-cleaning" && (
                <div className="md:grid md:grid-cols-3 mt-[40px] flex flex-col gap-y-10">
                  <div className="flex flex-col items-center justify-center">
                    <img
                      src={cleaningType.service1Image}
                      className="min-[350px]:h-[100px] min-[350px]:w-[100px] lg:h-[100px] lg:w-[100px]"
                      alt="Image"
                      width={127}
                      height={165}
                    />
                    <p className="mt-2 font-[Avenir] font-semibold min-[350px]:text-sm lg:text-base min-[350px]:max-w-[200px] lg:max-w-[200px] text-center">
                      {cleaningType.service1text}
                    </p>
                  </div>
                  <div className="flex flex-col items-center justify-center">
                    <img
                      src={cleaningType.service2Image}
                      className="min-[350px]:h-[100px] min-[350px]:w-[100px] lg:h-[100px] lg:w-[100px]"
                      alt="Image"
                      width={150}
                      height={165}
                    />
                    <p className="mt-2 font-[Avenir] font-semibold min-[350px]:text-sm lg:text-base min-[350px]:max-w-[200px] lg:max-w-[200px] text-center">
                      {cleaningType.service2text}
                    </p>
                  </div>
                  <div className="flex flex-col items-center justify-center">
                    <img
                      src={cleaningType.service3Image}
                      className="min-[350px]:h-[100px] min-[350px]:w-[100px] lg:h-[100px] lg:w-[100px]"
                      alt="Image"
                      width={127}
                      height={165}
                    />
                    <p className="mt-2 font-[Avenir] font-semibold min-[350px]:text-sm lg:text-base min-[350px]:max-w-[200px] lg:max-w-[150px] text-center">
                      {cleaningType.service3text}
                    </p>
                  </div>
                </div>
              )}
          </div>
          <div className="text-center min-[350px]:mt-[50px] lg:mt-[100px] mx-5 bg-gradient-to-l from-[#34BDD3] to-[#34D399] rounded-3xl px-4 py-4">
            <p className="font-[Avenir] uppercase font-bold text-md">
              <Link
                to="/book"
                className="underline text-lg hover:text-white transition ease-in-out duration-300"
              >
                Book Now
              </Link>{" "}
              and GET <span className="text-[#fa0202]">20% OFF</span> ON YOUR
              FIRST CLEANING{" "}
            </p>
          </div>
        </>
      </div>
    </div>
  );
};

export default CleaningPage;
