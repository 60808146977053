import person1 from "./images/happyperson.png";
import person2 from "./images/person6.png";
import person3 from "./images/person7.png";

const people = [
  {
    id: 1,
    image: person1,
    name: "Harry",
    quote:
      "I required a last-minute cleaning due to the previous cleaner failing to show up. I'm grateful for reaching out to you, as you promptly arrived within an hour and conducted a meticulous cleaning. You've earned a loyal customer.",
  },
  {
    id: 2,
    image: person2,
    name: "Yuna",
    quote:
      "I consistently appreciate the exceptional service I receive from your team. Your punctuality and courteous demeanor never go unnoticed. Our home remains consistently immaculate, and for that, I am truly grateful. Thank you!",
  },
  {
    id: 3,
    image: person3,
    name: "Kate",
    quote:
      "Since I devote a lot of time to my job and have no time to take care of my home, your team helps me with this every two weeks, and every time I am always impressed with the results. Thank you!",
  },
];

export default people;
