import bookingService from "../services/booking.service";

export const bookNow = async (payload) => {
  try {
    const data = await bookingService.bookNow(payload);
  } catch (error) {
    console.log(error);
  }
};

export const getQuote = async (payload) => {
  try {
    const data = await bookingService.getQuote(payload);
  } catch (error) {
    console.log(error);
  }
};
