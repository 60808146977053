import React from "react";

const MovingCleaning = ({
  service1Image,
  service1text,
  service2Image,
  service2text,
  service3Image,
  service3text,
  service4Image,
  service4text,
  service5Image,
  service5text,
  service6Image,
  service6text,
  service7Image,
  service7text,
  service8Image,
  service8text,
  service9Image,
  service9text,
  service10Image,
  service10text,
  service11Image,
  service11text,
  service12Image,
  service12text,
  service13Image,
  service13text,
  service14Image,
  service14text,
}) => {
  return (
    <div>
      <p className="text-center mt-10 uppercase font-[Avenir] text-xl font-bold tracking-wider">
        General
      </p>
      <div className="grid grid-cols-2 md:grid-cols-4 mt-[10px] gap-y-8 gap-x-8 px-14 flex flex-col border border-2 border-[#b5b5b5] rounded-3xl mx-5 py-10">
        <div className="flex flex-col items-center justify-center">
          <img
            src={service1Image}
            className="min-[350px]:h-[100px] min-[350px]:w-[100px] lg:h-[100px] lg:w-[100px]"
            width={127}
            height={148}
            alt="Image"
          />
          <p className="mt-2 font-[Avenir] font-semibold min-[350px]:text-sm lg:text-base min-[350px]:max-w-[200px] lg:max-w-[200px] text-center">
            {service1text}
          </p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <img
            src={service2Image}
            className="min-[350px]:h-[100px] min-[350px]:w-[100px] lg:h-[100px] lg:w-[100px]"
            width={127}
            height={165}
            alt="Image"
          />
          <p className="mt-2 font-[Avenir] font-semibold min-[350px]:text-sm lg:text-base min-[350px]:max-w-[250px] lg:max-w-[250px] text-center">
            {service2text}
          </p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <img
            src={service3Image}
            className="min-[350px]:h-[100px] min-[350px]:w-[100px] lg:h-[100px] lg:w-[100px]"
            width={127}
            height={148}
            alt="Image"
          />
          <p className="mt-2 font-[Avenir] font-semibold min-[350px]:text-sm lg:text-base min-[350px]:max-w-[150px] lg:max-w-[150px] text-center">
            {service3text}
          </p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <img
            src={service4Image}
            className="min-[350px]:h-[100px] min-[350px]:w-[100px] lg:h-[100px] lg:w-[100px]"
            width={127}
            height={148}
            alt="Image"
          />
          <p className="mt-2 font-[Avenir] font-semibold min-[350px]:text-sm lg:text-base min-[350px]:max-w-[150px] lg:max-w-[150px] text-center">
            {service4text}
          </p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <img
            src={service5Image}
            className="min-[350px]:h-[100px] min-[350px]:w-[100px] lg:h-[100px] lg:w-[100px]"
            width={127}
            height={148}
            alt="Image"
          />
          <p className="mt-2 font-[Avenir] font-semibold min-[350px]:text-sm lg:text-base min-[350px]:max-w-[150px] lg:max-w-[250px] text-center">
            {service5text}
          </p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <img
            src={service6Image}
            className="min-[350px]:h-[100px] min-[350px]:w-[100px] lg:h-[100px] lg:w-[100px]"
            width={127}
            height={148}
            alt="Image"
          />
          <p className="mt-2 font-[Avenir] font-semibold min-[350px]:text-sm lg:text-base min-[350px]:max-w-[150px] lg:max-w-[150px] text-center">
            {service6text}
          </p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <img
            src={service7Image}
            className="min-[350px]:h-[100px] min-[350px]:w-[100px] lg:h-[80px] lg:w-[80px]"
            width={127}
            height={165}
            alt="Image"
          />
          <p className="mt-2 font-[Avenir] font-semibold min-[350px]:text-sm lg:text-base min-[350px]:max-w-[220px] lg:max-w-[250px] text-center">
            {service7text}
          </p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <img
            src={service8Image}
            className="min-[350px]:h-[100px] min-[350px]:w-[100px] lg:h-[100px] lg:w-[125px]"
            width={145}
            height={101}
            alt="Image"
          />
          <p className="mt-2 font-[Avenir] font-semibold min-[350px]:text-sm lg:text-base min-[350px]:max-w-[220px] lg:max-w-[250px] text-center">
            {service8text}
          </p>
        </div>
      </div>
      <p className="text-center mt-[80px] uppercase font-[Avenir] text-xl font-bold tracking-wider">
        Kitchen
      </p>
      <div className="grid grid-cols-2 md:grid-cols-4 mt-[10px] gap-y-6 px-14 flex flex-col border border-2 border-[#b5b5b5] rounded-3xl mx-5 py-10">
        <div className="flex flex-col items-center justify-center">
          <img
            src={service9Image}
            className="min-[350px]:h-[100px] min-[350px]:w-[100px] lg:h-[110px] lg:w-[110px]"
            width={127}
            height={148}
            alt="Image"
          />
          <p className="font-[Avenir] font-semibold min-[350px]:text-sm lg:text-base min-[350px]:max-w-[200px] lg:max-w-[200px] text-center">
            {service9text}
          </p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <img
            src={service10Image}
            className="min-[350px]:h-[100px] min-[350px]:w-[100px] lg:h-[90px] lg:w-[90px]"
            width={127}
            height={165}
            alt="Image"
          />
          <p className="mt-2 font-[Avenir] font-semibold min-[350px]:text-sm lg:text-base min-[350px]:max-w-[250px] lg:max-w-[250px] text-center">
            {service10text}
          </p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <img
            src={service12Image}
            className="min-[350px]:h-[100px] min-[350px]:w-[100px] lg:h-[100px] lg:w-[100px]"
            width={127}
            height={148}
            alt="Image"
          />
          <p className="mt-2 font-[Avenir] font-semibold min-[350px]:text-sm lg:text-base min-[350px]:max-w-[150px] lg:max-w-[150px] text-center">
            {service12text}
          </p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <img
            src={service11Image}
            className="min-[350px]:h-[100px] min-[350px]:w-[100px] lg:h-[100px] lg:w-[100px]"
            width={127}
            height={148}
            alt="Image"
          />
          <p className="mt-2 font-[Avenir] font-semibold min-[350px]:text-sm lg:text-base min-[350px]:max-w-[150px] lg:max-w-[150px] text-center">
            {service11text}
          </p>
        </div>
      </div>
      <p className="text-center mt-[80px] uppercase font-[Avenir] text-xl font-bold tracking-wider">
        Bathroom
      </p>
      <div className="grid grid-cols-2 mt-[10px] lg:px-[200px] flex flex-col border border-2 border-[#b5b5b5] rounded-3xl mx-5 py-10">
        <div className="flex flex-col items-center justify-center">
          <img
            src={service13Image}
            className="min-[350px]:h-[100px] min-[350px]:w-[100px] lg:h-[110px] lg:w-[110px]"
            width={127}
            height={148}
            alt="Image"
          />
          <p className="mt-2 font-[Avenir] font-semibold min-[350px]:text-sm lg:text-base min-[350px]:max-w-[200px] lg:max-w-[200px] text-center">
            {service13text}
          </p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <img
            src={service14Image}
            className="min-[350px]:h-[100px] min-[350px]:w-[100px] lg:h-[110px] lg:w-[110px]"
            width={127}
            height={165}
            alt="Image"
          />
          <p className="mt-2 font-[Avenir] font-semibold min-[350px]:text-sm lg:text-base min-[350px]:max-w-[250px] lg:max-w-[250px] text-center">
            {service14text}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MovingCleaning;
