import React from "react";
import { Link } from "react-router-dom";
import BookingFormStripe from "./bookingForm";

const BookingPage = () => {
  return (
    <div className="mb-[50px]">
      <div className="font-[Avenir] max-md:text-center border-b-2 border-t-[1px] min-[350px]:text-3xl lg:text-6xl h-[100px] flex items-center justify-center text-[#13287E]">
        ONLINE BOOKING & PRICING
      </div>
      <div className="font-sans text-center mt-[20px] text-[#13287E]">
        <p className="font-bold font-[Avenir] mx-1 min-[350px]:text-2xl lg:text-4xl mb-[15px]">
          Book Instantly Online Below Now!
        </p>
        {/* <p className="font-semibold font-[Avenir] mx-1 min-[350px]:text-xl lg:text-2xl mb-[15px]">
          You won't be charged until the day of the cleaning!
        </p> */}
        <p className="font-[Avenir] min-[350px]:text-xl mx-1 lg:text-xl">
          Have a question first? Read our{" "}
          <Link
            to="/faqs"
            className="text-orange-400 hover:text-orange-500 transition ease-in-out duration-200"
          >
            FAQ page
          </Link>{" "}
          for answers
        </p>
      </div>

      <div className="mt-[40px] mx-auto border border-2 rounded-3xl border-[#AAF0D1] max-md:mx-5 shadow-xl max-w-[1000px] min-h-[800px]">
        <BookingFormStripe />
      </div>

      <div className="mx-[80px] text-center mt-[60px] text-gray-400">
        <div>
          To ensure the safety of our team, we refrain from tasks involving
          climbing ladders, cleaning or removing mold, handling deceased
          animals, managing animal waste, dealing with excessive garbage, or
          handling anything deemed a biohazard or unsafe.
        </div>
        <div className="mt-2">
          We do not tolerate any behavior that makes our team feel
          uncomfortable.
        </div>
        <div className="mt-2">
          We retain the right to decline a booking at any given time.
        </div>
      </div>
    </div>
  );
};

export default BookingPage;
