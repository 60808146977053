import httpService from "./http.service";
const contactEndpoint = "contact";

const contactService = {
  contact: async (payload) => {
    const { data } = await httpService.put(contactEndpoint, payload);
    return data;
  },
};

export default contactService;
