import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import styles from "./style.css";

const CARD_OPTIONS = {
  iconStyle: "solid",
  hidePostalCode: true,
  style: {
    base: {
      iconColor: "#33D19B",
      color: "#000",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      "::placeholder": { color: "#98A3B1" },
    },
    invalid: {
      iconColor: "#EC4547",
      color: "#EC4547",
    },
  },
};

export default function PaymentForm({ onChange }) {
  return (
    <>
      <fieldset className="shadow-sm ring-1 ring-inset ring-gray-300 rounded-md">
        <div className="FormRow">
          <CardElement options={CARD_OPTIONS} onChange={onChange} />
        </div>
      </fieldset>
    </>
  );
}
