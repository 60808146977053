import httpService from "./http.service";
const extrasEndpoint = "extras";

const extrasService = {
  fetchAll: async () => {
    const { data } = await httpService.get(extrasEndpoint);
    return data;
  },
};

export default extrasService;
