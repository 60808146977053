import React, { useState } from "react";
import { Disclosure, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { Rotate as Hamburger } from "hamburger-react";
import ServicesDropdown from "../servicesDropdown";
import header from "../../../assets/header.png";

const navigation = [
  { name: "Home", to: "/" },
  {
    name: <ServicesDropdown />,
  },
  { name: "Faq", to: "/faqs" },
  { name: "Contact Us", to: "/contact" },
  {
    name: "Book Now",
    to: "/book",
  },
  { name: "Get Quote", to: "/getquote" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Header = () => {
  const [isOpen, setOpen] = useState(false);
  // const menuRef = useRef();

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     // Если клик был вне области меню, закрываем меню
  //     if (menuRef.current && !menuRef.current.contains(event.target)) {
  //       setOpen(false);
  //     }
  //   };

  //   // Добавляем обработчик события клика при монтировании компонента
  //   document.addEventListener("click", handleClickOutside);

  //   // Убираем обработчик события клика при размонтировании компонента
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);
  return (
    <Disclosure
      as="nav"
      className="sticky top-0 z-50 bg-white border rounded-b-md"
    >
      {({ open, close }) => (
        <>
          <div className="mx-auto px-2 sm:px-6 lg:px-2 py-6 max-w-[1450px] ">
            <div className="relative flex h-10 items-center justify-between">
              <div className="flex sm:flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <Link to="/">
                    <img
                      className="w-auto w-[230px] h-[87px]"
                      width={235}
                      height={90}
                      src={header}
                      alt="Mint Cleaning Header Logo"
                    />
                  </Link>
                </div>
                <div className="hidden min-[1066px]:ml-[13px] min-[1150px]:ml-[55px] min-[1265px]:ml-[150px]  min-[1066px]:block">
                  <div className="flex space-x-4 uppercase mt-5">
                    {navigation.slice(0, 4).map((item) =>
                      item.name === "Home" ||
                      item.name === "Faq" ||
                      item.name === "Contact Us" ||
                      item.name === "Book Now" ||
                      item.name === "Get Quote" ? (
                        <Link
                          to={item.to}
                          key={item.name}
                          className={classNames(
                            item.current
                              ? "text-white bg-emerald-400 px-4 py-3 rounded-full"
                              : "text-black hover:bg-emerald-400 hover:text-white",
                            "text-lg font-['Avenir'] ease-in-out uppercase duration-[320ms] hover:text-white font-bold hover:bg-emerald-400 px-4 py-3 rounded-full transition"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </Link>
                      ) : (
                        <div
                          to={item.to}
                          key={item.name}
                          className={classNames(
                            item.current
                              ? "text-white bg-emerald-400 px-4 py-3 rounded-full"
                              : "text-black hover:bg-emerald-400 hover:text-white",
                            "text-lg font-['Avenir'] ease-in-out uppercase duration-300 hover:text-white font-bold hover:bg-emerald-400 px-4 py-3 rounded-full transition"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <div className="flex min-[500px]:mr-5 items-center min-[1066px]:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md text-gray-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white duration-[200ms] transition">
                    {/* <span className="absolute -inset-0.5" /> */}
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <Hamburger
                        direction="right"
                        toggled={isOpen}
                        toggle={setOpen}
                      />
                    ) : (
                      <Hamburger
                        direction="right"
                        toggled={isOpen}
                        toggle={setOpen}
                      />
                    )}
                  </Disclosure.Button>
                </div>
                <Link
                  to="/getquote"
                  className="mr-[30px] max-[1066px]:hidden font-['Avenir'] duration-300 ease-in-out hover:text-white uppercase font-bold bg-[#34BDD3] px-4 py-4 rounded hover:bg-[#27a0b3] transition text-lg"
                >
                  Get Quote
                </Link>
                <Link
                  to="/book"
                  className="max-[1066px]:hidden font-['Avenir'] font-bold duration-300 ease-in-out hover:text-white uppercase bg-emerald-400 px-4 py-4 rounded hover:bg-emerald-500 transition text-lg"
                >
                  Book Now
                </Link>
              </div>
            </div>
          </div>

          <Transition
            enter="transition duration-500 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-500 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className="min-[1066px]:hidden fixed w-full z-20 bg-white rounded-b-lg -mt-1">
              <div className="px-2 pb-3 pt-2">
                {navigation.map((item) =>
                  item.name === "Home" ||
                  item.name === "Faq" ||
                  item.name === "Contact Us" ||
                  item.name === "Book Now" ||
                  item.name === "Get Quote" ? (
                    <Disclosure.Button
                      to={item.to}
                      key={item.name}
                      as="div"
                      className={classNames(
                        item.current
                          ? "bg-emerald-400 text-white"
                          : "text-black",
                        "uppercase block rounded-md px-3 py-2 text-base font-medium font-['Gabarito'] duration-[150ms] transition"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      <Link
                        to={item.to}
                        onClick={() => {
                          close();
                          setOpen(!isOpen);
                        }}
                      >
                        {" "}
                        {item.name === "Book Now" ||
                        item.name === "Get Quote" ? (
                          item.name === "Book Now" ? (
                            <p className="bg-emerald-400 border rounded-md py-2 px-2 font-semibold">
                              {item.name}
                            </p>
                          ) : (
                            <p className="bg-[#34BDD3] border rounded-md py-2 px-2 font-semibold">
                              {item.name}
                            </p>
                          )
                        ) : (
                          <p className="px-2">{item.name}</p>
                        )}
                      </Link>
                    </Disclosure.Button>
                  ) : (
                    <Disclosure.Button
                      to={item.to}
                      key={item.name}
                      as="div"
                      className={classNames(
                        item.current
                          ? "bg-emerald-400 text-white"
                          : "text-black",
                        "uppercase block rounded-md px-3 py-2 text-base font-medium font-['Gabarito'] duration-[150ms] transition"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      <div
                        to={item.to}
                        onClick={() => {
                          close();
                          setOpen(!isOpen);
                        }}
                      >
                        <div className="px-2">{item.name}</div>
                      </div>
                    </Disclosure.Button>
                  )
                )}
              </div>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};

export default Header;
