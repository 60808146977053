import React from "react";

const PrivacyPolicyPage = () => {
  return (
    <div>
      <div className="mt-5 mb-12">
        <div className="text-center font-[Avenir] text-5xl font-bold">
          Privacy Policy
        </div>
        <div className="font-[Avenir] mx-auto mt-8 max-lg:px-4 lg:max-w-[1080px]">
          In order to provide quality cleaning services for your home, Mint
          Cleaning must collect, use and disclose information in relation to our
          customers. This information is personal, which is why we make the
          following commitments to our customers:
        </div>
        <div className="font-[Avenir] mx-auto mt-5 max-lg:px-4 lg:max-w-[1080px]">
          Mint Cleaning will only collect the personal information necessary to
          provide Mint Cleaning customers with high-quality residential cleaning
          services.
        </div>
        <div className="font-[Avenir] mx-auto mt-5 max-lg:px-4 lg:max-w-[1080px]">
          Mint Cleaning does not collect, use or disclose any personal
          information about a customer to a third party without the consent of
          the customer, except where required by law or as authorized to do so
          by the Privacy Act.
        </div>

        <div className="font-[Avenir] mx-auto mt-5 max-lg:px-4 lg:max-w-[1080px]">
          Mint Cleaning uses a password-protected software program which has a
          database that contains personal information about our customers. Mint
          Cleaning also collects, in written form, personal information about a
          customer. This information is kept in a secure location with
          controlled access.
        </div>
        <div className="font-[Avenir] mx-auto mt-5 max-lg:px-4 lg:max-w-[1080px]">
          Personal information about our customers, including but not limited to
          their name, address and telephone number, must be disclosed to the
          employees of Mint Cleaning to provide the cleaning service. Mint
          Cleaning employees have acknowledged their requirements to maintain
          the confidentiality of this information and will only use it to
          fulfill the requirements of their employment with Mint Cleaning.
          Personal information entrusted to our employees is kept confidential
          and secure at all times.
        </div>
        <div className="font-[Avenir] mx-auto mt-5 max-lg:px-4 lg:max-w-[1080px]">
          Mint Cleaning customers are entitled to examine the personal
          information we have on file for them, subject to the restrictions
          provided by law. Customers may also request rectification of
          inaccurate or incomplete information. If applicable, we will disclose
          this information to the person concerned or rectify it promptly.
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
