const TextField = ({
  label,
  type,
  name,
  placeholder,
  value,
  onChange,
  error,
  className1,
  className2,
  className3,
  className4,
}) => {
  const getInputClasses = () => {
    return error
      ? "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-red-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm lg:text-base sm:leading-6"
      : "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#71d1a7] sm:text-sm lg:text-base sm:leading-6";
  };
  return (
    <div className={className1}>
      <label htmlFor={name} className={className2}>
        {label}
      </label>
      <div className={className3}>
        <input
          type={type}
          placeholder={placeholder}
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          className={className4 ? className4 : getInputClasses()}
        />
        {error && <p className="mt-2 ml-1 text-sm text-red-600">{error}</p>}
      </div>
    </div>
  );
};

export default TextField;
