import React, { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import routes from "../routes";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import ScrollToTop from "./components/scrollToTop";
import { useDispatch } from "react-redux";
import { loadExtrasList } from "./store/extras";
import { loadCleaningsList } from "./store/cleanings";
import { loadFrequenciesList } from "./store/frequency";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const elements = useRoutes(routes());
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadExtrasList());
    dispatch(loadCleaningsList());
    dispatch(loadFrequenciesList());
  }, []);
  return (
    <div className="mx-auto">
      <Header />
      {elements}
      <Footer />
      <ScrollToTop />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
