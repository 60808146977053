import React from "react";

const OptionField = ({
  name,
  value,
  onChange,
  className1,
  className2,
  className3,
  option1,
  option2,
  option3,
  option4,
  option5,
  option6,
  option7,
  option8,
  option9,
  option10,
  option11,
  option12,
  option13,
  option14,
  option15,
}) => {
  return (
    <div className={className1}>
      <div className={className2}>
        <select
          id={name}
          name={name}
          onChange={onChange}
          value={value}
          className={className3}
        >
          <option>{option1}</option>
          {option2 && <option>{option2}</option>}
          {option3 && <option>{option3}</option>}
          {option4 && <option>{option4}</option>}
          {option5 && <option>{option5}</option>}
          {option6 && <option>{option6}</option>}
          {option7 && <option>{option7}</option>}
          {option8 && <option>{option8}</option>}
          {option9 && <option>{option9}</option>}
          {option10 && <option>{option10}</option>}
          {option11 && <option>{option11}</option>}
          {option12 && <option>{option12}</option>}
          {option13 && <option>{option13}</option>}
          {option14 && <option>{option14}</option>}
          {option15 && <option>{option15}</option>}
        </select>
      </div>
    </div>
  );
};

export default OptionField;
